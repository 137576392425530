const LocationReviewsData = [
    {
        "date": "2024-03-25",
        "review_rate": 5.0,
        "customer_name": "Rachel Barr",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Eric Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Alyssa H.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Stanley",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michele M.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Catherine Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Tamara J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Joshua Fields",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Robin M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Kimberly Sherman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle H.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Jennifer Stewart",
        "service_type": "Garage Door Opener",
        "tech_name": "Michelle W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Victoria Burns",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Charles W.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-28",
        "review_rate": 4.9,
        "customer_name": "Leslie Bartlett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Vanessa C.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jay Small",
        "service_type": "Garage Door Opener",
        "tech_name": "Alexander S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Jennifer Rubio",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Renee F.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Emily Anderson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Stephanie S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Brian Lopez",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bonnie P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Gabrielle Serrano",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Brian W.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-02-17",
        "review_rate": 5.0,
        "customer_name": "Mark Wright",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jesse S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Courtney Ramos",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samuel M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Denise Hayden",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shaun P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-03",
        "review_rate": 4.9,
        "customer_name": "Cody Fowler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Marcus H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Leah Tran",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Michael J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Evan Allen",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jose G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Christopher Woodward",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeremy M.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-02",
        "review_rate": 5.0,
        "customer_name": "Jennifer Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danny G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Adam Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brandi O.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Candice Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Shelly J.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Charlotte Bailey",
        "service_type": "Garage Door Repair",
        "tech_name": "Morgan B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Julie Brown",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Michael O.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.9,
        "customer_name": "Tiffany Fuller",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Courtney C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-10",
        "review_rate": 5.0,
        "customer_name": "Eric Young",
        "service_type": "Garage Door Maintenance",
        "tech_name": "David G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Dawn Watson",
        "service_type": "Garage Door Repair",
        "tech_name": "Vanessa P.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Curtis Daugherty",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Grant V.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Andrew Hall",
        "service_type": "Garage Door Repair",
        "tech_name": "Danielle E.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Karen Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephanie T.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Carol Foster",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rodney L.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Mrs. Leslie Gonzalez MD",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Diana E.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-24",
        "review_rate": 5.0,
        "customer_name": "Lisa Parker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Seth S.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Jacob Hayes",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Robin W.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Kristin Rose",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jerome E.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Tyler Wheeler",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Rebecca R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Melissa Howell",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Diana Ochoa",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Justin G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Jesse Mcgee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Alex H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-27",
        "review_rate": 5.0,
        "customer_name": "Joseph Dean",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Scott P.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Ashley Wright",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jonathan B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Aaron Perez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Michelle S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jonathan Ford",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sandra T.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Sarah Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa H.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Maria Ward",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Morgan T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.8,
        "customer_name": "Diamond Nielsen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Leslie M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Carol Petty",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erika W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Savannah Rios",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jorge P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Anaheim and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Anaheim"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Christopher Hicks",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Edward M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Connie Long",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Alan Haley",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Summer B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-23",
        "review_rate": 5.0,
        "customer_name": "Cheryl Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Clinton M.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-01",
        "review_rate": 4.9,
        "customer_name": "Amanda Caldwell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Bradley B.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-06",
        "review_rate": 5.0,
        "customer_name": "Emily Burke",
        "service_type": "Garage Door Insulation",
        "tech_name": "Robert W.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Austin Watson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Michael A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-30",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Valenzuela",
        "service_type": "Garage Door Off Track",
        "tech_name": "Robin W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Fernando Colon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amanda H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Dawn Miller",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven H.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Donald Foster",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shawn M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-22",
        "review_rate": 4.9,
        "customer_name": "Jasmine Pennington",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joel C.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Richard Perez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Benjamin M.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Nicole Graham",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jessica M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Sherri Cross",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lawrence M.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Steve Callahan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Rebecca J.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Michael Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Wendy V.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-27",
        "review_rate": 5.0,
        "customer_name": "Michael Cook",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Yesenia P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Melissa Madden",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Willie S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Erika Mitchell",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ashley W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Deborah West",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Sandra Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Paul R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 5.0,
        "customer_name": "Janet Allison",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sherri H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Joseph Patel",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Samantha F.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Bernard Rivas",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joshua B.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Heather Fuller",
        "service_type": "Garage Door Opener",
        "tech_name": "Shaun H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Erika Martin",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Sara K.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Jennifer Meyer",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Leah B.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.8,
        "customer_name": "Christopher White",
        "service_type": "Garage Door Opener",
        "tech_name": "Colleen W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.9,
        "customer_name": "Gene Rodriguez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Justin M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Roberto Alvarez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Leslie V.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Brian Thomas",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Carol K.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Timothy Baker",
        "service_type": "Garage Door Repair",
        "tech_name": "Sarah C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-10",
        "review_rate": 4.9,
        "customer_name": "Richard Smith",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Margaret S.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.9,
        "customer_name": "Suzanne Ward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Joseph C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Matthew Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Jennifer M.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Karen Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Summer P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Kirsten Rivera",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brittany A.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Regina Nolan",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nancy J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Barbara Doyle",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica S.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Mark Contreras",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Autumn K.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-08-16",
        "review_rate": 5.0,
        "customer_name": "Natalie Wells",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brittany B.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Jonathan Kelly",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Sheila C.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Brian Flores",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Cathy R.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.9,
        "customer_name": "John Walker",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jim W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Sandra Wright",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Kimberly M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-05-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert T.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Daniel Ruiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Eric N.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-07-07",
        "review_rate": 5.0,
        "customer_name": "Robin Reed",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Vincent F.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Douglas Curtis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Pamela L.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Calabasas and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Calabasas"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Janet Harris",
        "service_type": "Garage Door Services",
        "tech_name": "Jeremiah S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Christina Carney",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie O.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Jonathan Cantu",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Todd Carlson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Cynthia A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Sarah Riggs",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Jessica Pham",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Benjamin H.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Danielle Welch",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Meghan C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Russell Hamilton",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Joseph N.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Colton Gonzales",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth J.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Paul Wagner",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "James J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "James Stevenson",
        "service_type": "Garage Door Opener",
        "tech_name": "Tony M.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Susan Thompson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kurt P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Sheri Cruz",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Gina W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Jack Hansen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Paul C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Laurie Nguyen",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brenda S.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Kathleen Grant",
        "service_type": "Garage Door Off Track",
        "tech_name": "Natalie D.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Amber Ayers",
        "service_type": "Garage Door Opener",
        "tech_name": "Jessica K.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Daniel Mcknight",
        "service_type": "Garage Door Services",
        "tech_name": "Gail A.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Samantha M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Tommy Bell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Patricia K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Dr. Carlos Daniels",
        "service_type": "Garage Door Insulation",
        "tech_name": "Kathryn C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Samantha Hernandez",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jean J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "John Smith",
        "service_type": "Garage Door Repair",
        "tech_name": "Jason M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Stuart Wilson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey C.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Anna Harris",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Stephen Jones",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Catherine T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Bruce Greer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sarah M.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Shawn Roberts",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kenneth R.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-15",
        "review_rate": 4.8,
        "customer_name": "Victoria Farmer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Richard H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Susan Smith",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Donna B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Eric Williams",
        "service_type": "Garage Door Services",
        "tech_name": "Thomas F.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.8,
        "customer_name": "William Knight",
        "service_type": "Garage Door Repair",
        "tech_name": "Todd S.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Collin Brown",
        "service_type": "Garage Door Installation",
        "tech_name": "Michael M.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Shawn Williamson DDS",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Marco J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Antonio Phillips",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian A.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 5.0,
        "customer_name": "Jennifer Murillo",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer Y.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Terry Hinton",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dawn M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Audrey Pratt",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin R.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Mark Patterson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Steven D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Caroline Mckenzie",
        "service_type": "Garage Door Repair",
        "tech_name": "Jesse R.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Noah Patel",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "John R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Lynn Sullivan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer F.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Michelle Mendoza",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Alexander Jackson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michael P.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "William Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gerald M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-06",
        "review_rate": 5.0,
        "customer_name": "Ellen Rivas",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Shelby Y.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Melissa Jensen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Dawn D.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Claudia Pierce",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Bridget J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Julie Hernandez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Thomas N.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.8,
        "customer_name": "Angela Perry",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tabitha S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Corona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Corona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Kelly Ross",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Eric M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Audrey Johnson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Maria Hughes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Robert W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.8,
        "customer_name": "Sophia Hester",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jennifer A.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Amanda Caldwell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Chris B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Hannah Abbott",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Antonio L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Nina Fisher",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Bryan S.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Stacy Gates",
        "service_type": "Garage Door Insulation",
        "tech_name": "Theresa L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Arthur Henderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maurice H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "James Walsh",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stefanie P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-27",
        "review_rate": 5.0,
        "customer_name": "Mrs. Bethany Rodgers",
        "service_type": "Garage Door Insulation",
        "tech_name": "Mary W.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.9,
        "customer_name": "Alex Simpson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Stephanie J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Daniel Smith",
        "service_type": "Garage Door Installation",
        "tech_name": "Angela S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Diamond Rowe",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jimmy J.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Stephens",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Elizabeth T.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Amy Mitchell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Samantha C.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "Brandi Burns MD",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Steven F.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Edwin Diaz",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Katherine B.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-29",
        "review_rate": 4.9,
        "customer_name": "Linda Davis",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Lauren Phillips",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Nicole G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.8,
        "customer_name": "Christopher Nguyen",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Randy M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-07",
        "review_rate": 4.8,
        "customer_name": "Sean Scott",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Gabriela D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Deborah Bell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Don B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-07-01",
        "review_rate": 4.8,
        "customer_name": "George Robertson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kimberly T.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-25",
        "review_rate": 4.8,
        "customer_name": "Christina Reese",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Amy H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-17",
        "review_rate": 5.0,
        "customer_name": "Nicole Davis",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Lisa T.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Yesenia Best",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Billy B.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-02",
        "review_rate": 5.0,
        "customer_name": "Kylie Watson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Matthew J.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "Brittany Spears",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan W.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Martin Livingston",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-08-18",
        "review_rate": 5.0,
        "customer_name": "Michael Hicks",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marcus F.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Jason Burns",
        "service_type": "Garage Door Installation",
        "tech_name": "Sharon S.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Todd Cruz",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Suzanne M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Morgan Schroeder",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Kimberly S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-05-17",
        "review_rate": 4.8,
        "customer_name": "Shawn Murray",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "John Jackson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Wendy P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Mr. Shane Evans",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stacy L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Robert Jimenez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Victoria H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.8,
        "customer_name": "Cassandra Kerr",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jonathan W.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Mr. Tony Whitney",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Victoria R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Stanley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 5.0,
        "customer_name": "Christina Cobb",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Angela M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Carolyn Gutierrez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patricia M.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-10-26",
        "review_rate": 5.0,
        "customer_name": "Michael Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon B.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Brian Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Robert W.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Shawna Berger",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael L.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Angela Perez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Carol F.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-19",
        "review_rate": 4.9,
        "customer_name": "Jordan Mullen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Cody B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2023-12-11",
        "review_rate": 4.9,
        "customer_name": "Brian Rose",
        "service_type": "Garage Door Installation",
        "tech_name": "Erica G.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jacob Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Denise S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Fontana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Fontana"
    },
    {
        "date": "2024-01-10",
        "review_rate": 4.9,
        "customer_name": "Patricia Goodwin",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Julie A.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Nathan Kramer",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Luis B.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Anthony Porter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Melinda R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-29",
        "review_rate": 5.0,
        "customer_name": "Angela Garcia",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Harold H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Courtney Scott",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Patrick R.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Joshua Koch",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Christina Castillo",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Cynthia P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-29",
        "review_rate": 5.0,
        "customer_name": "Ruth Lopez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amy S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Michael Fleming",
        "service_type": "Garage Door Off Track",
        "tech_name": "Stephanie H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Duarte",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Mr. Lee Taylor",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Malik P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Sarah Reyes",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Shane S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Brittany Wright",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Dana S.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Bell",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Theodore M.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "Jonathan Hull",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Martin L.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Stephanie Whitaker",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kathy R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Dakota Mayer",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Robert G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.8,
        "customer_name": "Valerie Gonzales",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Amber L.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Douglas Flores",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Laura G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Christine Potter",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stephanie N.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Susan Hodge",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Christopher Wright",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James S.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-19",
        "review_rate": 4.8,
        "customer_name": "Jessica Martin DDS",
        "service_type": "Garage Door Installation",
        "tech_name": "Melinda P.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.8,
        "customer_name": "Kristen Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Tyler J.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-30",
        "review_rate": 4.9,
        "customer_name": "Joel Wells",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Cheryl R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "David Stewart",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William R.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Rebecca Mahoney",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gregory M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Derek Barber",
        "service_type": "Garage Door Opener",
        "tech_name": "James T.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-20",
        "review_rate": 4.8,
        "customer_name": "Kristina Massey",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Audrey W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Cassandra Cruz",
        "service_type": "Garage Door Repair",
        "tech_name": "Daniel S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Brittany Kim",
        "service_type": "Garage Door Installation",
        "tech_name": "Joshua T.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.8,
        "customer_name": "Stephen Hamilton",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christine G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Nicholas Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Wesley M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.9,
        "customer_name": "Jared Brown",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Holly W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Alexandria Harper",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Rebecca K.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Melanie Maynard",
        "service_type": "Garage Door Off Track",
        "tech_name": "David H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Dr. Stacy Brown MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "John M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Raven Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Mrs. Sydney Yang",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jerry F.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Lisa Roberts",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Bryan L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Stephanie Silva",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lori M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Paul Morse",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Carl M.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Susan Arnold",
        "service_type": "Garage Door Repair",
        "tech_name": "Jared C.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-03-26",
        "review_rate": 4.9,
        "customer_name": "Yvonne Duffy",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kelly M.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-20",
        "review_rate": 5.0,
        "customer_name": "Anthony Alvarez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michelle H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Thomas Brown",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brian B.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Ronnie Neal",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Douglas P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Cody Long",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lynn G.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Travis Collins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Keith L.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-06-27",
        "review_rate": 4.9,
        "customer_name": "Kim Luna",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Anthony B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Glendale and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Glendale"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Martin Moore",
        "service_type": "Garage Door Installation",
        "tech_name": "Mary G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-23",
        "review_rate": 4.9,
        "customer_name": "Shawn Parks",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jeffrey B.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Tracy Diaz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Julie H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Jeremy Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bradley S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Derrick Andrews",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Katherine G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.8,
        "customer_name": "Robert Salazar",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michelle P.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.8,
        "customer_name": "Natasha Holt",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Adam L.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Kevin Stokes",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Nicholas A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.8,
        "customer_name": "Stephen Kim",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Harold M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.9,
        "customer_name": "Cody Moore",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Rachel R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Jordan Juarez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Joseph W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Rhonda Garner",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jeffrey M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Carol Clark",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alexandra H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.9,
        "customer_name": "Nancy Woodward",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Tammy Jordan",
        "service_type": "Garage Door Services",
        "tech_name": "Kimberly T.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-16",
        "review_rate": 4.9,
        "customer_name": "Michael Mann",
        "service_type": "Garage Door Services",
        "tech_name": "Gwendolyn W.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Steven Jackson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Wendy G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Cheryl Arias",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kathleen D.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Perez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tiffany A.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Emily Palmer",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ryan V.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Sharon Perkins DVM",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Daniel D.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Melissa Cabrera",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Michael W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Brent Taylor",
        "service_type": "Garage Door Services",
        "tech_name": "Rhonda M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Kimberly Allen",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stacy D.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.9,
        "customer_name": "Kyle Jones",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Ann B.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Nathan Cline DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sarah E.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Joseph Conley",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-18",
        "review_rate": 4.9,
        "customer_name": "Lauren Burch",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michael A.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Mary Alvarado",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.8,
        "customer_name": "Fernando Lambert",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amber P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Robert Hernandez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Daniel T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-01",
        "review_rate": 5.0,
        "customer_name": "Kimberly Richmond",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Amy M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-19",
        "review_rate": 5.0,
        "customer_name": "Madison Johnston",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Chad B.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.9,
        "customer_name": "Pamela Hernandez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Matthew P.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Steven Miller",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Sheri R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Alyssa Bryant",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Allison F.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Gina Rodriguez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Megan M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Frank Armstrong",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Ryan T.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-20",
        "review_rate": 5.0,
        "customer_name": "Eric Adams",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Natalie H.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Hector Woods",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Robert W.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Robert Campbell",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Crystal Macias",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Charles L.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Ryan Powell Jr.",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ronald P.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Daniel Chase",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Vernon N.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Lisa Edwards",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Scott S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Danielle Long",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lori R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Christopher Simpson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer D.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.9,
        "customer_name": "Amy King",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Lawrence S.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Jessica Cabrera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kelli T.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2023-09-27",
        "review_rate": 4.9,
        "customer_name": "Michael Ramirez DDS",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Dylan C.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Long Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Long Beach"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Mrs. Kelly Barton",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Joseph J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Mark Wallace",
        "service_type": "Garage Door Repair",
        "tech_name": "Jared W.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Deborah Frost",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tammy P.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Amanda Estrada",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jay G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Craig Villarreal",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anna W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.8,
        "customer_name": "Amanda Cole",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Roger Floyd",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amanda M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Christina Perry",
        "service_type": "Garage Door Opener",
        "tech_name": "Joshua M.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-08",
        "review_rate": 5.0,
        "customer_name": "Amy Glenn",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kyle C.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Kristen Howard",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Mackenzie G.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Joyce Chung",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brad R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Alfred Frye",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jessica O.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-19",
        "review_rate": 5.0,
        "customer_name": "Hannah Berry",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Lee Y.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.8,
        "customer_name": "Lisa Ward",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kevin B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Angelica Jones",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Zachary W.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.8,
        "customer_name": "Patrick Miller",
        "service_type": "Garage Door Insulation",
        "tech_name": "Marissa H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-12",
        "review_rate": 5.0,
        "customer_name": "Joshua Frederick",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Corey G.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.9,
        "customer_name": "Derek Reed",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristine C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Michael Austin",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Peter H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.8,
        "customer_name": "Kelly Stewart MD",
        "service_type": "Garage Door Repair",
        "tech_name": "Susan L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Angela Moran",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Elizabeth G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "Sandra Chase",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Sharon M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Jonathan Bautista",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Olivia S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-31",
        "review_rate": 5.0,
        "customer_name": "Paul Dean",
        "service_type": "Residential Garage Door Services",
        "tech_name": "James R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Sara Nguyen",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie M.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Rebecca Pittman",
        "service_type": "Garage Door Opener",
        "tech_name": "Michelle H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.8,
        "customer_name": "Kathleen Bowman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Austin G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.8,
        "customer_name": "Katherine Holden",
        "service_type": "Garage Door Repair",
        "tech_name": "Peggy O.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Candice Best",
        "service_type": "Garage Door Opener",
        "tech_name": "Julie G.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Natalie Nolan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Sandra M.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Joseph Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Jeff Miller",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Rose B.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.8,
        "customer_name": "Meredith Mcfarland MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tony W.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.9,
        "customer_name": "Margaret Jones",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Susan C.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Wolfe",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Keith G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Hampton",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Edward M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Steven King",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dennis K.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-01-16",
        "review_rate": 5.0,
        "customer_name": "Sabrina Fritz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Janet J.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-04-07",
        "review_rate": 5.0,
        "customer_name": "Casey Reese",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mike C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-20",
        "review_rate": 5.0,
        "customer_name": "Kevin Bautista",
        "service_type": "Garage Door Installation",
        "tech_name": "Kelsey B.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Sheila Ramirez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "William G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Denise Orr",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer R.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Ana Knapp",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Jay M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Pamela Hayes",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Theresa R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Donna Morton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Brittany Ewing",
        "service_type": "Garage Door Repair",
        "tech_name": "Corey R.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2023-10-28",
        "review_rate": 5.0,
        "customer_name": "Matthew Jones",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Elizabeth G.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-02-03",
        "review_rate": 5.0,
        "customer_name": "Brandon Wilson",
        "service_type": "Garage Door Repair",
        "tech_name": "Gabriela G.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Nathan Bird",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melinda R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Andrew Watkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tammy W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Moreno Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Moreno Valley"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Suzanne Mcbride",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Henry T.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Monica Vargas",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Valerie S.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Natasha Carpenter",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Brett P.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Anna Rivera",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Sergio K.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-17",
        "review_rate": 5.0,
        "customer_name": "Courtney Brandt",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Dominique R.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Charles Ramirez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew S.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Wayne Barrett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tonya P.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Dennis Rubio",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kelly W.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Julie Beck",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kathryn T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Aaron Mosley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joshua K.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-05",
        "review_rate": 5.0,
        "customer_name": "Shane Leblanc",
        "service_type": "Garage Door Repair",
        "tech_name": "James T.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Terri Estrada",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alison B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Robert Mays",
        "service_type": "Garage Door Insulation",
        "tech_name": "Gregory W.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Olivia Baker",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Donald L.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Michael Rodriguez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Melissa L.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Shirley Hebert",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brittney S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Virginia Brown",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Wesley H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.8,
        "customer_name": "Jessica Lewis",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William L.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Michael Murillo",
        "service_type": "Garage Door Installation",
        "tech_name": "Ronald G.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Calvin Garrison",
        "service_type": "Garage Door Installation",
        "tech_name": "Paul W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Kyle Leonard",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Alexandra H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-30",
        "review_rate": 4.9,
        "customer_name": "Alice Ramirez",
        "service_type": "Garage Door Opener",
        "tech_name": "Erika R.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Regina Parker",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Richard M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.9,
        "customer_name": "Margaret Flores",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Philip T.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Jesse Mccarty",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Amanda S.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-22",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Ortiz",
        "service_type": "Garage Door Installation",
        "tech_name": "Tony M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Michelle Soto",
        "service_type": "Garage Door Repair",
        "tech_name": "Kristy L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Ann Smith",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Amanda D.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-11-17",
        "review_rate": 4.9,
        "customer_name": "Joseph Garza",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sandra R.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Steve Haley",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth C.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Maria Murphy",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Victor C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Emily Grimes",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Steven B.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Karen Young",
        "service_type": "Garage Door Services",
        "tech_name": "Greg M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Shawn Hebert",
        "service_type": "Garage Door Opener",
        "tech_name": "Joe A.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Kevin Roth",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Paul C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Rachael Ayala",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Carolyn S.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "Emily Sweeney",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kristin W.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Gregory Townsend",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "John W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Joseph Allison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Claudia T.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Cindy Gonzalez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Scott H.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.9,
        "customer_name": "Andrew Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Daryl J.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.8,
        "customer_name": "Matthew Clark",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael J.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "James Sawyer DDS",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Sherry R.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Anna Martin",
        "service_type": "Garage Door Opener",
        "tech_name": "Eugene H.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.8,
        "customer_name": "Ashley Cunningham",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sylvia S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Maria Fitzgerald",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Timothy D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Garrett Boyd",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Makayla G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.8,
        "customer_name": "Bradley Morales",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Andrew V.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Deborah Haney",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Robert S.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Donald Norman DVM",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Ashley H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Oxnard and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Oxnard"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Jonathan Saunders",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Zachary F.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Mcgee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rachel M.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Taylor Rodriguez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Francisco A.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.8,
        "customer_name": "Summer Chandler",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jessica O.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Jonathan Higgins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lisa F.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Deanna Jacobs",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Angela M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Nicholas Coleman",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Robert R.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-20",
        "review_rate": 4.9,
        "customer_name": "Lucas Herman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Abigail S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Kim Gonzalez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melanie W.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Gina Walters",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin R.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Jared Marks",
        "service_type": "Garage Door Services",
        "tech_name": "Joseph B.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Carrie Jones",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Thomas J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-18",
        "review_rate": 4.9,
        "customer_name": "David Everett",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Rachel Beck",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Adrian D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-01-02",
        "review_rate": 5.0,
        "customer_name": "Samantha Sanchez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Michelle D.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "April Welch",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amy G.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.9,
        "customer_name": "Benjamin Hughes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Suzanne L.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-05-30",
        "review_rate": 4.9,
        "customer_name": "Hannah Stephenson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jeremy G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Donna Hernandez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Debra J.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Holly Taylor",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jennifer S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Angela Henderson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Marcus L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "Brooke Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Judy G.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "William Graves",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Robert B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-09",
        "review_rate": 5.0,
        "customer_name": "Robert Huynh",
        "service_type": "Garage Door Off Track",
        "tech_name": "Adam L.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-07",
        "review_rate": 4.9,
        "customer_name": "Jose Guerra",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "James A.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.9,
        "customer_name": "Amanda Calderon",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Curtis S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Cochran",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kenneth N.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Megan Moore",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Rebecca C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Amber Chambers",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Shelby H.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-28",
        "review_rate": 5.0,
        "customer_name": "Eric Sharp",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nathaniel J.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-18",
        "review_rate": 4.9,
        "customer_name": "Robert George",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Darlene S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Jeremy Scott",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Laura W.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-10",
        "review_rate": 4.9,
        "customer_name": "Kenneth Quinn",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Seth S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-13",
        "review_rate": 4.8,
        "customer_name": "Claire Miller",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "David J.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Madeline Johnson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Kendra M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Carrie Hoffman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer W.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Ariel Guzman",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kenneth S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "William Cole",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Gary L.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Julie Santos",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Courtney G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.8,
        "customer_name": "Nathan Williams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jay L.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Glenn Lewis",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jeffrey A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.9,
        "customer_name": "Roberto Boyle",
        "service_type": "Garage Door Installation",
        "tech_name": "Jose H.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-09-19",
        "review_rate": 4.9,
        "customer_name": "Daniel Thompson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Robert S.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-11-19",
        "review_rate": 4.9,
        "customer_name": "Justin Anderson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Lori H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.9,
        "customer_name": "Jeremiah Hill",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Wendy B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.9,
        "customer_name": "Mr. Justin Shannon",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Tracy A.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.8,
        "customer_name": "Rachel Bell",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Donna G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Phillip Hudson",
        "service_type": "Garage Door Opener",
        "tech_name": "Timothy S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2023-09-26",
        "review_rate": 5.0,
        "customer_name": "Kiara Ruiz",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Dalton P.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Carol Bass",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kyle K.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pasadena and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pasadena"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Jeremy Munoz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jamie B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Robert Martinez",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Lorraine O.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-15",
        "review_rate": 5.0,
        "customer_name": "Scott Romero",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Donna S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 5.0,
        "customer_name": "Brittany Evans",
        "service_type": "Garage Door Insulation",
        "tech_name": "Ivan F.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Lee Gomez",
        "service_type": "Garage Door Installation",
        "tech_name": "Jonathan H.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-17",
        "review_rate": 4.8,
        "customer_name": "Kyle Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Taylor B.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-25",
        "review_rate": 5.0,
        "customer_name": "Madeline Price",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nancy J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.8,
        "customer_name": "Kyle Weber",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michael W.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Jamie Hendricks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura F.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Edward Oliver",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Kimberly W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Michael Jackson",
        "service_type": "Garage Door Repair",
        "tech_name": "Angel H.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.9,
        "customer_name": "John Montoya",
        "service_type": "Garage Door Repair",
        "tech_name": "Steve D.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Daniel Dean",
        "service_type": "Garage Door Services",
        "tech_name": "Christian S.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-01",
        "review_rate": 5.0,
        "customer_name": "Susan Reyes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Daniel Y.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Gene Morris",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lindsay H.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Mario Haley",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Michelle B.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Caleb Jacobs",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mackenzie G.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Charles Parsons",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Harry S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Anthony Ferguson",
        "service_type": "Garage Door Installation",
        "tech_name": "Cole B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-03",
        "review_rate": 4.8,
        "customer_name": "Andrea Huff",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Amanda D.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Monica Barron",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Daniel H.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Christian Mclean",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tricia Z.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Mr. Thomas Ingram MD",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Nicole H.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Anna Thomas",
        "service_type": "Garage Door Off Track",
        "tech_name": "Joshua H.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Samuel Hanson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Anthony A.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Richard Dodson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jared S.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.9,
        "customer_name": "Lisa Cole",
        "service_type": "Garage Door Insulation",
        "tech_name": "Tammy S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Philip Lindsey",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Zachary A.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Richard White",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Travis R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-06-03",
        "review_rate": 5.0,
        "customer_name": "Ashley Clark DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Dennis D.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.9,
        "customer_name": "Justin Luna",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Nicholas W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Amber Mcclain",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Logan B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Courtney Brown",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Brandon L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Angelica Jacobs",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ernest F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Christopher Johnson",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christina M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Victoria Harris",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brittany P.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.9,
        "customer_name": "Robert Carney",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Stanley R.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "James Garrett",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Tyler P.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Cassidy Pena",
        "service_type": "Garage Door Opener",
        "tech_name": "Anna H.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-02-29",
        "review_rate": 4.9,
        "customer_name": "David Haley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Christopher B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-08",
        "review_rate": 4.9,
        "customer_name": "Adam Townsend",
        "service_type": "Garage Door Repair",
        "tech_name": "Adrian W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-24",
        "review_rate": 4.9,
        "customer_name": "Anthony Hinton",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mike B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Patricia Mcfarland",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Brian S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "John Sims",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Steven S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Megan Dunn",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jason P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-11-20",
        "review_rate": 5.0,
        "customer_name": "Gabriel Webb",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "William A.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.8,
        "customer_name": "Laura Khan",
        "service_type": "Garage Door Installation",
        "tech_name": "Natalie T.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.9,
        "customer_name": "Phillip Hendricks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Nicole S.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Michelle Edwards",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Matthew P.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Rebecca Jones DVM",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Brandon M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Pomona and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Pomona"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Lopez",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Heidi B.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Pamela Frazier",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melanie K.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Cory Wise",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Diana H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Ronald Black",
        "service_type": "Garage Door Services",
        "tech_name": "Kathleen M.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Dustin Lopez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brent O.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.9,
        "customer_name": "James Hall",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "James G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Anna Smith DDS",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Jacob Whitaker",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Maria P.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Lisa Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dana H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "George Krueger",
        "service_type": "Garage Door Off Track",
        "tech_name": "David K.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.9,
        "customer_name": "Erica Lewis",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Laura O.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Devin Hamilton",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Zoe M.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Juan White",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Tabitha W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-31",
        "review_rate": 4.9,
        "customer_name": "Nicholas Hill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Troy G.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "David Wise",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tara B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Mr. Craig Castaneda MD",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Haley S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.9,
        "customer_name": "Laura Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cindy G.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-10",
        "review_rate": 4.9,
        "customer_name": "Robert Dorsey",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Steven J.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.8,
        "customer_name": "Renee Henderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Jessica K.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Melissa Levine",
        "service_type": "Garage Door Opener",
        "tech_name": "Vincent C.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-29",
        "review_rate": 4.9,
        "customer_name": "George Barber",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-15",
        "review_rate": 4.9,
        "customer_name": "Eric Gutierrez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Garrett B.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-11-12",
        "review_rate": 5.0,
        "customer_name": "Kelly Hickman",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Amanda H.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Daniel Frey",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Andrea H.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-06",
        "review_rate": 4.9,
        "customer_name": "Maria Flores",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Carla B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Marco Hensley",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joseph M.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Heidi Brown",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kevin H.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.9,
        "customer_name": "Connie Fox",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tiffany S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.8,
        "customer_name": "Martin Cortez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Emily N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Kayla Yoder",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Michael B.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Jared Curtis",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "Carl Swanson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.8,
        "customer_name": "Jonathan Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Samuel Todd",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-11",
        "review_rate": 5.0,
        "customer_name": "Ryan Lucas Jr.",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Michael G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-19",
        "review_rate": 5.0,
        "customer_name": "Michael Nelson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Ryan G.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Lori Ballard",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Kim Warren",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christian V.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-15",
        "review_rate": 5.0,
        "customer_name": "Carolyn Clark",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Chris C.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Melissa Taylor",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jamie N.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Susan Cantrell",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mary A.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-17",
        "review_rate": 5.0,
        "customer_name": "Angela Rojas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "April W.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-10",
        "review_rate": 5.0,
        "customer_name": "John Baker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Suzanne B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Pamela Hernandez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Steven W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Michael Harris",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Justin F.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-03-29",
        "review_rate": 5.0,
        "customer_name": "Ana Scott MD",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Garrett F.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Samantha Barron",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James L.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Sarah Jones",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Karl Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Ashley I.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2024-05-04",
        "review_rate": 5.0,
        "customer_name": "Justin Knight",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Pamela C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rancho Cucamonga and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rancho Cucamonga"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Matthew Turner",
        "service_type": "Garage Door Opener",
        "tech_name": "Zachary D.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Jason Doyle",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Ronald T.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-08",
        "review_rate": 4.9,
        "customer_name": "Charles Griffin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "John D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.9,
        "customer_name": "Danielle Wells",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly L.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-30",
        "review_rate": 5.0,
        "customer_name": "Theresa Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Valerie H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Dawn Salas",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Jessica O.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.9,
        "customer_name": "Andrew Stein",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kim Y.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-18",
        "review_rate": 5.0,
        "customer_name": "Shane Perkins",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Brian G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Castillo",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kaitlin G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Thomas Kaiser",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brandon S.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Pamela Hamilton",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gina B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "John Mcbride",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Felicia S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-02",
        "review_rate": 4.8,
        "customer_name": "William Gill",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Allison M.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-21",
        "review_rate": 5.0,
        "customer_name": "Bryan King",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Renee B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Kristina Fritz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Andrew V.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-12",
        "review_rate": 4.9,
        "customer_name": "Jasmine Benton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Gregory N.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Wendy Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-10",
        "review_rate": 5.0,
        "customer_name": "George Holmes",
        "service_type": "Garage Door Installation",
        "tech_name": "Amy B.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Gina Snyder",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Ashley H.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.9,
        "customer_name": "Amanda Schmidt",
        "service_type": "Garage Door Services",
        "tech_name": "Renee S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Henry Swanson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Raven M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Justin Robinson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sharon M.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-28",
        "review_rate": 4.9,
        "customer_name": "Samantha Huynh",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Stephen W.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Rachael Wilson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jeremy J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-28",
        "review_rate": 5.0,
        "customer_name": "Mark Gutierrez",
        "service_type": "Garage Door Services",
        "tech_name": "Diane S.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.8,
        "customer_name": "Dr. Ian Johnson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Connie Owens",
        "service_type": "Garage Door Repair",
        "tech_name": "Kevin A.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Kathy Long",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.8,
        "customer_name": "Daniel Hill",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kathleen E.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Vanessa Davis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Melinda S.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Craig Munoz",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Catherine H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Michael Arellano",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher W.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.9,
        "customer_name": "Scott Rojas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anna P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-09",
        "review_rate": 4.9,
        "customer_name": "Steve Castro",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Loretta W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Amanda Lee",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tracy A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Joshua Rivera",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Daniel F.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Melissa Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Bethany H.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Gabriel Maxwell",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary A.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.8,
        "customer_name": "Anthony Pierce",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Aimee M.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Robert Hutchinson",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Andrew W.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.9,
        "customer_name": "Brian Clark",
        "service_type": "Garage Door Installation",
        "tech_name": "Samuel N.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Christian Pittman",
        "service_type": "Residential Garage Door Services",
        "tech_name": "William J.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.8,
        "customer_name": "Kristine Adams",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stacey C.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.8,
        "customer_name": "Andrew Brooks",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Connor G.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Michael Walker",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ashley B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Jeremy Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Linda S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-11-02",
        "review_rate": 4.8,
        "customer_name": "Kayla Gordon",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Anna P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-27",
        "review_rate": 4.9,
        "customer_name": "Paul Jones",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Candace W.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Richard Ruiz",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Gregory A.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Kathryn Garcia",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Maria J.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Redlands and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Redlands"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Sandra Hester",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michelle A.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.8,
        "customer_name": "Kyle Fitzgerald",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephanie D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Megan Davis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Timothy N.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Susan Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brandon H.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Linda Nguyen",
        "service_type": "Garage Door Opener",
        "tech_name": "Caleb P.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Gerald Russell",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Jane Z.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Theresa Knight",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Jonathan J.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Carla Moore",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Amanda B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Kelly Romero",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Sandra S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Donald Nelson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer S.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Michelle Brown",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Patrick H.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Jessica Lee",
        "service_type": "Custom Garage Door Design",
        "tech_name": "William B.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Melanie Campbell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Diane M.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-23",
        "review_rate": 5.0,
        "customer_name": "Lisa Martinez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Mark P.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-14",
        "review_rate": 5.0,
        "customer_name": "Katrina Phillips",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Megan C.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Mr. Robert Webb DVM",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Justin B.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Brittany Black",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Dana S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Tom Orozco",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Adriana T.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-13",
        "review_rate": 4.9,
        "customer_name": "James Hughes",
        "service_type": "Garage Door Repair",
        "tech_name": "Derrick T.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Theresa Moore",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Caitlin V.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Mariah Lester",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shannon R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Anthony Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Raymond C.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Bryan Morgan",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Stephen S.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Nancy Cooper",
        "service_type": "Garage Door Insulation",
        "tech_name": "Margaret D.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-24",
        "review_rate": 4.8,
        "customer_name": "Danny Ramirez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jill H.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-02",
        "review_rate": 4.8,
        "customer_name": "Larry Sloan",
        "service_type": "Garage Door Repair",
        "tech_name": "Michael S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.9,
        "customer_name": "Stacey Daniel",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Alexis M.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Michelle Gonzales",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jason S.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Robin Cannon",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Taylor W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Michael Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Matthew F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-05",
        "review_rate": 4.9,
        "customer_name": "Adam Randolph",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Tricia P.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.8,
        "customer_name": "Tammy Mills",
        "service_type": "Garage Door Installation",
        "tech_name": "John W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Tanya Kane",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michelle B.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Dawn Hoffman",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Kelly J.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "Ashley Anderson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Michelle G.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-11",
        "review_rate": 5.0,
        "customer_name": "Renee Butler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Amanda B.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Suzanne Flynn",
        "service_type": "Garage Door Insulation",
        "tech_name": "Sean Y.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-02-01",
        "review_rate": 4.9,
        "customer_name": "Rick Cortez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Kathryn R.",
        "review": "The technician was friendly and professional at all times. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-18",
        "review_rate": 5.0,
        "customer_name": "Kevin Perez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Carl F.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Mary Guerra",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Audrey M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Amber Gomez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Ryan S.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Andrew Howard",
        "service_type": "Garage Door Services",
        "tech_name": "Steven B.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Cindy Adams",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Adam M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Frank Roman",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Stephanie D.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "Zachary Robinson",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gary K.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Roberts",
        "service_type": "Garage Door Opener",
        "tech_name": "April J.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-05-06",
        "review_rate": 5.0,
        "customer_name": "Jeffery Beck",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tracy G.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.9,
        "customer_name": "Blake Jones",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Samuel C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Mary Gregory",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Elizabeth C.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "James Odonnell",
        "service_type": "Garage Door Services",
        "tech_name": "Amber A.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Rialto and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Rialto"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.9,
        "customer_name": "Susan Serrano",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Scott M.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-11",
        "review_rate": 4.8,
        "customer_name": "Gloria Nelson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amy G.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Jacob Goodwin",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Dr. Tammy Tapia",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Erin E.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Pamela Terry",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Sarah J.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-29",
        "review_rate": 4.8,
        "customer_name": "Laura Arnold",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samuel B.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Amanda Pena",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Samuel J.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "Aaron Walker",
        "service_type": "Garage Door Installation",
        "tech_name": "Joseph B.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Burgess",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "James W.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "John Sexton",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Marilyn H.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Jessica Perez",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Clayton L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Samuel Davidson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Andrew W.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Anthony Atkinson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Nancy O.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Kirsten White",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christina F.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.9,
        "customer_name": "Patricia Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Steven M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Ellen Davis",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Tiffany D.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.9,
        "customer_name": "Jessica Owen",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sean B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-28",
        "review_rate": 4.8,
        "customer_name": "Mark Gill",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Anthony S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-12",
        "review_rate": 5.0,
        "customer_name": "Stephen Jones",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mary W.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.8,
        "customer_name": "Thomas Trujillo",
        "service_type": "Garage Door Off Track",
        "tech_name": "Chloe G.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.8,
        "customer_name": "Adam Wang MD",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Sandra P.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Rebecca Wiggins",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Brenda K.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-27",
        "review_rate": 5.0,
        "customer_name": "Sarah Cruz",
        "service_type": "Garage Door Repair",
        "tech_name": "Rebecca M.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Michael Melendez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kelly S.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Devin Harris",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Paul A.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Kevin Sampson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Peter R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Michael Ayala",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Rachel V.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Robin King",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tara H.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Mary Benjamin",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher R.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Morgan Johnson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Colleen H.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-12-14",
        "review_rate": 4.9,
        "customer_name": "Michael Martinez",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Matthew H.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-04-19",
        "review_rate": 4.8,
        "customer_name": "Patrick Vance",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Hayley G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "Janet Garcia",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Leslie H.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Taylor Lewis",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard R.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Stuart Sims",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Louis M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-03",
        "review_rate": 5.0,
        "customer_name": "Anthony Jones",
        "service_type": "Garage Door Opener",
        "tech_name": "Lori B.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.8,
        "customer_name": "Susan Cook",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Roberta S.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-21",
        "review_rate": 5.0,
        "customer_name": "William Parker",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Ruth M.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-09",
        "review_rate": 4.9,
        "customer_name": "Mary Young",
        "service_type": "Garage Door Insulation",
        "tech_name": "Pamela N.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-09",
        "review_rate": 5.0,
        "customer_name": "Joanna Hill",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Eric S.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Thomas Lewis",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Elizabeth G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Laura Adams",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Dana E.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Christine Clark DVM",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Tara M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-01-28",
        "review_rate": 5.0,
        "customer_name": "Thomas Ray",
        "service_type": "Garage Door Services",
        "tech_name": "Austin R.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Renee Anderson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William B.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Erin Swanson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Daisy S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.9,
        "customer_name": "Daniel Collins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Meredith N.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.9,
        "customer_name": "Gina Ellis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sheila U.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Jessica Wilkinson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Heather F.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Billy Meyers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Daniel H.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Riverside and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Riverside"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Gregory Horton",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Tammy H.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Jennifer Hernandez",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Brenda P.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Patricia Watson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Valerie E.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.8,
        "customer_name": "Karen Roberts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sean G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.8,
        "customer_name": "Cameron Baker MD",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Matthew H.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Erik Baker",
        "service_type": "Garage Door Insulation",
        "tech_name": "Laura M.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Joseph Pham",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Veronica H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.9,
        "customer_name": "Trevor Lopez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Mario B.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.8,
        "customer_name": "Roy Ortega",
        "service_type": "Garage Door Insulation",
        "tech_name": "Rachel R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Robert Miller",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Sarah G.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Jamie Ware",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Julie S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Ann Villarreal",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Rebecca A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Curtis Farrell",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Robert A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-24",
        "review_rate": 5.0,
        "customer_name": "Crystal Combs",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Karen M.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.8,
        "customer_name": "Susan Strickland",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan S.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-27",
        "review_rate": 5.0,
        "customer_name": "Wendy Nelson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Stephen H.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.8,
        "customer_name": "Spencer Mcgrath",
        "service_type": "Garage Door Insulation",
        "tech_name": "Charles C.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Luke Nelson",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Erik J.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.9,
        "customer_name": "Mckenzie Whitehead",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Lauren D.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-02",
        "review_rate": 5.0,
        "customer_name": "Mark Cooper",
        "service_type": "Garage Door Installation",
        "tech_name": "Lucas H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Brittany Mckinney DDS",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Ashley L.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Meghan Hernandez",
        "service_type": "Garage Door Repair",
        "tech_name": "Kelli M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-04",
        "review_rate": 4.8,
        "customer_name": "Anna Young",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Heather W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.9,
        "customer_name": "Mrs. Linda Murphy MD",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Bryan S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-13",
        "review_rate": 5.0,
        "customer_name": "Kristy Jackson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Edward L.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.8,
        "customer_name": "Karen Griffith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Vincent T.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-24",
        "review_rate": 4.9,
        "customer_name": "Katherine Ford",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "John G.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-24",
        "review_rate": 5.0,
        "customer_name": "Nicholas Allen",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Barbara C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Russell Mahoney",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Dana S.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Christopher Clark",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jessica H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "Lindsey Thompson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Jason T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-09-28",
        "review_rate": 5.0,
        "customer_name": "Kendra Rose",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John R.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-06",
        "review_rate": 4.9,
        "customer_name": "Matthew Robertson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brianna V.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.9,
        "customer_name": "Roger Ortiz",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Robert S.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Stephanie Williams",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lori A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.8,
        "customer_name": "Wesley Hernandez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tami W.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-02",
        "review_rate": 4.9,
        "customer_name": "Cameron Berry",
        "service_type": "Garage Door Repair",
        "tech_name": "Eddie W.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-07",
        "review_rate": 4.9,
        "customer_name": "April Cortez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Cody H.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Allison Davis",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Christopher H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-04",
        "review_rate": 4.9,
        "customer_name": "Jason Schmidt",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jeffrey P.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Alyssa Kim",
        "service_type": "Garage Door Services",
        "tech_name": "Brenda C.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2023-11-19",
        "review_rate": 5.0,
        "customer_name": "Paige Howard",
        "service_type": "Garage Door Off Track",
        "tech_name": "Danielle C.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-11",
        "review_rate": 4.8,
        "customer_name": "David Hoffman",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Jessica T.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Tracey Gamble",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Christina M.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-01-31",
        "review_rate": 5.0,
        "customer_name": "Michelle Mayer DVM",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jennifer W.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Bradley Turner",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin C.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Denise Smith",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Danny B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Carrie Clark MD",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alyssa C.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-09-01",
        "review_rate": 4.9,
        "customer_name": "Susan Olson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Mike L.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Tracy Brewer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Patricia T.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Bernardino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Bernardino"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Christina Henry",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jeffery H.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Melody Owens",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Brian F.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Lisa Hunt",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kelly T.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Nicole Hernandez",
        "service_type": "Garage Door Installation",
        "tech_name": "Alexander M.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-09-30",
        "review_rate": 4.8,
        "customer_name": "Amy Phillips",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Austin C.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Alicia Harper",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Johnny N.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.9,
        "customer_name": "Barry Kemp",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Brandi S.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Danny Rush",
        "service_type": "Garage Door Services",
        "tech_name": "Anna T.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Ashley Shaw MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kelsey Y.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Jeremy Torres",
        "service_type": "Garage Door Services",
        "tech_name": "Tonya R.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.8,
        "customer_name": "Dr. Brandon Moore MD",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Courtney H.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Arthur Pham",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Caitlin J.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Glenn Mccoy",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jordan P.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-17",
        "review_rate": 4.9,
        "customer_name": "Cheryl Chen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jerry H.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Daniel Fischer",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-03",
        "review_rate": 5.0,
        "customer_name": "Erin Lambert",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kristin P.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-08",
        "review_rate": 4.9,
        "customer_name": "Melody King",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-15",
        "review_rate": 5.0,
        "customer_name": "Nicholas Roberson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Elizabeth S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-08",
        "review_rate": 4.9,
        "customer_name": "Marcus Waller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Wendy B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Jonathan Wiggins",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie F.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Austin Herrera",
        "service_type": "Garage Door Services",
        "tech_name": "Victoria G.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Jill Ellis",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Christopher M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-02",
        "review_rate": 5.0,
        "customer_name": "Jamie Edwards",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Randy D.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Gary Wilcox Jr.",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Joseph R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-23",
        "review_rate": 4.8,
        "customer_name": "Joseph Gill",
        "service_type": "Garage Door Services",
        "tech_name": "Travis A.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.8,
        "customer_name": "Jesse Cannon",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nicholas M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-13",
        "review_rate": 4.9,
        "customer_name": "Matthew Floyd",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Maria C.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Lori Bond",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Shawn M.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Nicholas Ballard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Rebecca S.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Kimberly Kelly",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mark D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Brian Gallagher DDS",
        "service_type": "Residential Garage Door Services",
        "tech_name": "April B.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.8,
        "customer_name": "Anthony Davis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "David F.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-03-31",
        "review_rate": 4.8,
        "customer_name": "James Edwards",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Stephanie G.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Megan Burton PhD",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Amy B.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-16",
        "review_rate": 4.9,
        "customer_name": "Robert Thornton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Laura L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.9,
        "customer_name": "Sarah King",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Mary F.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Jonathan Garza",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jeffrey G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Nicholas Casey",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert M.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Daniel Beasley",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shawn S.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-05",
        "review_rate": 4.9,
        "customer_name": "Julie Hill",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Laura M.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-01-11",
        "review_rate": 4.8,
        "customer_name": "Brenda Collins",
        "service_type": "Garage Door Opener",
        "tech_name": "Brian S.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-09-07",
        "review_rate": 4.9,
        "customer_name": "Jonathan Greene",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine O.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "John Ryan",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alexis S.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Anthony Rush",
        "service_type": "Garage Door Repair",
        "tech_name": "Mercedes C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Aaron Lopez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jo Lewis",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Miguel C.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.8,
        "customer_name": "Isaiah Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "David N.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Kevin Perez",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jose R.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Janet Arnold",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Teresa G.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-06-13",
        "review_rate": 4.9,
        "customer_name": "Holly Harris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brian R.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Ana and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Ana"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.9,
        "customer_name": "Ronald Yang",
        "service_type": "Garage Door Insulation",
        "tech_name": "Julie D.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-21",
        "review_rate": 5.0,
        "customer_name": "Philip Torres",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Phillip C.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "George Freeman",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-25",
        "review_rate": 4.9,
        "customer_name": "Brandon Erickson",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Glenn O.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Jill Price",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Lisa H.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Alyssa Rice",
        "service_type": "Garage Door Opener",
        "tech_name": "Aaron T.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-10",
        "review_rate": 4.8,
        "customer_name": "John Evans",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Beverly G.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Steven Bean",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Zachary B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Krystal Conway",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Denise D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Kimberly Freeman",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Christie M.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-08",
        "review_rate": 4.9,
        "customer_name": "Betty Benton",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Christine Kaiser",
        "service_type": "Garage Door Services",
        "tech_name": "Michael D.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Matthew Diaz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Shaun W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-17",
        "review_rate": 4.9,
        "customer_name": "Mary Nixon",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Evan W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jessica Smith",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tonya C.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.9,
        "customer_name": "Robert Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Christopher C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Michael Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Angelica H.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Amanda Hall",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Samuel S.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-12",
        "review_rate": 5.0,
        "customer_name": "Monique Atkinson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michele R.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-09",
        "review_rate": 4.9,
        "customer_name": "Regina Day",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Phillip R.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-20",
        "review_rate": 4.8,
        "customer_name": "Tracy Grimes",
        "service_type": "Garage Door Track Repair",
        "tech_name": "James Y.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-06",
        "review_rate": 4.8,
        "customer_name": "Jennifer Diaz",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.9,
        "customer_name": "David Gonzalez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley C.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Patty Lamb",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sean W.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Jeff Crawford",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Linda W.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.8,
        "customer_name": "Lynn Vega",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Mark S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Yvonne Leonard MD",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Anthony B.",
        "review": "I felt valued as a customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Ashley Clark",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Erica W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Albert Green",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Teresa C.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.9,
        "customer_name": "Jennifer Dixon",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Autumn G.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-17",
        "review_rate": 4.9,
        "customer_name": "Ashley Ruiz",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Tabitha G.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "John Atkins",
        "service_type": "Garage Door Installation",
        "tech_name": "Donald R.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Zachary Bryant",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Phillip G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Nancy Curtis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua B.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-10-21",
        "review_rate": 4.9,
        "customer_name": "Rebecca Cole",
        "service_type": "Garage Door Services",
        "tech_name": "Brianna B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.8,
        "customer_name": "Gregory Rice",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Matthew W.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-07-09",
        "review_rate": 4.9,
        "customer_name": "Amy White",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Richard B.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-02-09",
        "review_rate": 4.8,
        "customer_name": "Mary Olson",
        "service_type": "Garage Door Repair",
        "tech_name": "Marissa M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.9,
        "customer_name": "Carol Preston",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Cynthia W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Michael Roberts",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Alicia L.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.8,
        "customer_name": "Katherine Pearson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Misty H.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Natalie Solis",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Nicole M.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-09",
        "review_rate": 5.0,
        "customer_name": "Brianna Owens",
        "service_type": "Garage Door Insulation",
        "tech_name": "Angel F.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.9,
        "customer_name": "Kristina Williamson",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Harold C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Jodi Davis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Nicole R.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-01-13",
        "review_rate": 4.9,
        "customer_name": "Mary Wilcox",
        "service_type": "Garage Door Opener",
        "tech_name": "Jeffrey K.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-25",
        "review_rate": 5.0,
        "customer_name": "Megan Watkins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Heather C.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-06-22",
        "review_rate": 5.0,
        "customer_name": "Michael Simon",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Walter B.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.9,
        "customer_name": "Jake Taylor",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura B.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2024-08-21",
        "review_rate": 5.0,
        "customer_name": "Summer Williams",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jeffrey A.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Temecula and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Temecula"
    },
    {
        "date": "2023-11-30",
        "review_rate": 4.9,
        "customer_name": "Tamara Mitchell",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patrick K.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Kurt Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bethany W.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Frank Jennings",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Christopher G.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Katrina Herrera",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kevin M.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Schwartz",
        "service_type": "Garage Door Off Track",
        "tech_name": "Brian C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.9,
        "customer_name": "Michelle Hanson",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christopher M.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "David Arnold",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Gregory G.",
        "review": "The quality of work on my garage door was top-notch. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Johnny Fernandez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Travis M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-22",
        "review_rate": 4.9,
        "customer_name": "William Carter",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jamie N.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Christopher Caldwell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Jeffrey B.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Cassandra Gilbert",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Jeanette G.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-11",
        "review_rate": 4.9,
        "customer_name": "Craig Carter",
        "service_type": "Garage Door Off Track",
        "tech_name": "Jodi H.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-16",
        "review_rate": 4.9,
        "customer_name": "Ashley Holmes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan W.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Randall Leonard Jr.",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Theresa S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Douglas Leon",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Manuel P.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.8,
        "customer_name": "Jorge Vance",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Laurie C.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Troy Preston",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Susan V.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.9,
        "customer_name": "Jonathan Cook",
        "service_type": "Garage Door Services",
        "tech_name": "Michael J.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Patrick Green",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patrick L.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-10",
        "review_rate": 4.9,
        "customer_name": "Ryan Colon",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Kelly T.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Savannah Oconnor DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Rebecca C.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.8,
        "customer_name": "Melanie Hunter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Melissa T.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Michael Armstrong",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "James L.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-30",
        "review_rate": 4.8,
        "customer_name": "Mrs. Jamie Hall",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jonathan M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-14",
        "review_rate": 5.0,
        "customer_name": "Jennifer Rosario",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Scott B.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-09",
        "review_rate": 5.0,
        "customer_name": "Michelle Peterson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Paul H.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Ryan Walker",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tina M.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Paul White",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Christine R.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-05-27",
        "review_rate": 4.9,
        "customer_name": "Karen Lawrence",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Julia B.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-06",
        "review_rate": 4.8,
        "customer_name": "Miss Lisa Murray",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mark W.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Brian Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Michael O.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Joseph Benson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Samantha N.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Sean Yoder",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Christopher C.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-12",
        "review_rate": 4.9,
        "customer_name": "Michael Cuevas",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Jerry J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-17",
        "review_rate": 4.8,
        "customer_name": "Stephanie Sparks",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Benjamin B.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-01-25",
        "review_rate": 4.9,
        "customer_name": "Jose Cannon",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Brian L.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.8,
        "customer_name": "Gilbert Jacobs",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "John M.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.8,
        "customer_name": "Andrew Smith",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Scott M.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "James Chen",
        "service_type": "Garage Door Insulation",
        "tech_name": "Danielle T.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.8,
        "customer_name": "Brandy Holder",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Christine P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Jennifer Torres",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Patrick R.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-20",
        "review_rate": 4.8,
        "customer_name": "Jodi Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Autumn M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.8,
        "customer_name": "Ryan Jones",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Alexandria M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-02",
        "review_rate": 4.9,
        "customer_name": "Michael Tucker",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lawrence P.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-12",
        "review_rate": 5.0,
        "customer_name": "John Wright PhD",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Laura W.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "David Price",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jessica G.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Paul Garcia",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Erin M.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Jennifer Ellis",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lawrence T.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-08-02",
        "review_rate": 5.0,
        "customer_name": "Amy Wiggins",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Diana C.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Jay Jackson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Omar B.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Thousand Oaks and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Thousand Oaks"
    },
    {
        "date": "2024-03-31",
        "review_rate": 5.0,
        "customer_name": "Cameron Knox",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brandy H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-13",
        "review_rate": 5.0,
        "customer_name": "Alyssa Decker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Anthony G.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-16",
        "review_rate": 5.0,
        "customer_name": "Jennifer Snyder",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alexander N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "James Boyd",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Mandy B.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-28",
        "review_rate": 5.0,
        "customer_name": "Melissa Torres",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Alicia B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-13",
        "review_rate": 5.0,
        "customer_name": "Kathleen Howard",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Edward I.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-26",
        "review_rate": 5.0,
        "customer_name": "Kelly Miller",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Benjamin C.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-09",
        "review_rate": 5.0,
        "customer_name": "Jacqueline Parker",
        "service_type": "Garage Door Opener",
        "tech_name": "Richard M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-26",
        "review_rate": 4.9,
        "customer_name": "Daniel Harrison",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tanya K.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-26",
        "review_rate": 4.9,
        "customer_name": "Anna Griffith",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Deborah O.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.8,
        "customer_name": "Sherry Howard",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alexis P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Caitlyn Huang",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brad W.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-11",
        "review_rate": 5.0,
        "customer_name": "Christine Schneider",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Bryan V.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-21",
        "review_rate": 5.0,
        "customer_name": "Michael Green",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Ryan H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.9,
        "customer_name": "Melissa Marshall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jeffrey P.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-08",
        "review_rate": 5.0,
        "customer_name": "Kyle Ramirez",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Stephanie H.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-07",
        "review_rate": 5.0,
        "customer_name": "Garrett Weiss",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "David H.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Vicki Smith",
        "service_type": "Garage Door Opener",
        "tech_name": "Christopher B.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "David Juarez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Hannah S.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Ryan Robinson",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jeffrey A.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-10-14",
        "review_rate": 4.8,
        "customer_name": "Kaitlyn White",
        "service_type": "Garage Door Repair",
        "tech_name": "Stacy F.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Gould",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Tracy H.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.9,
        "customer_name": "Erin Morrison",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Caroline C.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.9,
        "customer_name": "Jonathan Patton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Patrick C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-03",
        "review_rate": 4.8,
        "customer_name": "Nathan White",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Gabrielle L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Justin Weaver",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Christina T.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.9,
        "customer_name": "Ashley Paul DDS",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christina J.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Christine Riggs",
        "service_type": "Garage Door Opener",
        "tech_name": "Carlos C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.8,
        "customer_name": "Douglas Johnson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Anita A.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-04-18",
        "review_rate": 4.8,
        "customer_name": "Kerri Dawson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sara G.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-01",
        "review_rate": 5.0,
        "customer_name": "Wesley Martinez",
        "service_type": "Garage Door Services",
        "tech_name": "Erin M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-23",
        "review_rate": 5.0,
        "customer_name": "Brian Herrera",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Christopher C.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-16",
        "review_rate": 4.9,
        "customer_name": "Diana Sullivan",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Margaret K.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.8,
        "customer_name": "Steven Barnett",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Erik B.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.9,
        "customer_name": "Tara Davis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Daniel L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Cheryl Freeman",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Dorothy L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.8,
        "customer_name": "Ricky Ramsey",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Joseph B.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.9,
        "customer_name": "Shari Armstrong",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Kendra A.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-07-03",
        "review_rate": 4.9,
        "customer_name": "Heather Wilson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Raymond G.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-08",
        "review_rate": 5.0,
        "customer_name": "Emily Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Juan B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-07",
        "review_rate": 4.9,
        "customer_name": "Stephen White",
        "service_type": "Garage Door Repair",
        "tech_name": "Toni S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-04",
        "review_rate": 5.0,
        "customer_name": "Todd Mcdaniel",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Marcus B.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "David Haynes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Daniel F.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.8,
        "customer_name": "Francis Miller",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Susan S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Kaylee Boyd",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "James W.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Heidi Sellers",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Lisa R.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Mary Richards",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Robert F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2023-12-03",
        "review_rate": 4.9,
        "customer_name": "Angela Melton",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Candice L.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-03-29",
        "review_rate": 4.8,
        "customer_name": "Courtney Green",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary C.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-01-03",
        "review_rate": 5.0,
        "customer_name": "William Anderson",
        "service_type": "Garage Door Installation",
        "tech_name": "Anne P.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Torrance and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Torrance"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Sean Mullen",
        "service_type": "Garage Door Repair",
        "tech_name": "Laura S.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Thomas Garcia",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Christie L.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-01",
        "review_rate": 5.0,
        "customer_name": "Nathan Johnson",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Elizabeth G.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Christy Carroll",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Tammy W.",
        "review": "The service felt personal and catered to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-20",
        "review_rate": 4.9,
        "customer_name": "Heather Underwood",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jacob R.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-15",
        "review_rate": 5.0,
        "customer_name": "Carly Myers",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Peter J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.8,
        "customer_name": "Christina Mercer",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Antonio P.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.9,
        "customer_name": "Samantha Mitchell",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Lisa G.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.9,
        "customer_name": "Hannah Jimenez",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Brian M.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-18",
        "review_rate": 4.9,
        "customer_name": "Kelly Lawson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Wendy E.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Christine Mendez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Joseph H.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-06",
        "review_rate": 4.8,
        "customer_name": "Peter Williams",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Amy M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Marvin David",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James D.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Michelle Ellison",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Stacie S.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.9,
        "customer_name": "Sean Long",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Richard T.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Brandy Martinez",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Alisha S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-16",
        "review_rate": 5.0,
        "customer_name": "Kim Gutierrez",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Jacob F.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Carl Blackburn",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Sarah M.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Matthew Richardson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kendra R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Ruth Byrd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Kyle B.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "April Lopez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Laura L.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-15",
        "review_rate": 4.8,
        "customer_name": "Peter Kennedy",
        "service_type": "Garage Door Off Track",
        "tech_name": "Judy B.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-15",
        "review_rate": 4.8,
        "customer_name": "Jacqueline Preston",
        "service_type": "Garage Door Services",
        "tech_name": "James F.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.9,
        "customer_name": "Jonathan Lewis",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Rebecca S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-13",
        "review_rate": 5.0,
        "customer_name": "Brady Ramirez",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Colleen B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-11-09",
        "review_rate": 4.8,
        "customer_name": "Anthony Young",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Derrick M.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-21",
        "review_rate": 4.9,
        "customer_name": "Jason Evans",
        "service_type": "Garage Door Services",
        "tech_name": "Jessica B.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.9,
        "customer_name": "Matthew Randolph",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jeanne M.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-22",
        "review_rate": 5.0,
        "customer_name": "Ann Dunn",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kimberly V.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-02-18",
        "review_rate": 5.0,
        "customer_name": "Anthony Matthews",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Susan W.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-06",
        "review_rate": 4.9,
        "customer_name": "Erin Rogers",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Nathan C.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.8,
        "customer_name": "Kelli Gonzales",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert L.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.9,
        "customer_name": "Shannon Hardy",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Brandon D.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Ellis",
        "service_type": "Garage Door Services",
        "tech_name": "Nathan R.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Theresa Taylor",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Nathaniel E.",
        "review": "I was delighted with the level of care taken during the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-29",
        "review_rate": 4.9,
        "customer_name": "Tamara Martin",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Donna M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-21",
        "review_rate": 4.9,
        "customer_name": "Derek Taylor",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Jason G.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-18",
        "review_rate": 5.0,
        "customer_name": "Claire Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Peter C.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-05-07",
        "review_rate": 4.8,
        "customer_name": "Samuel Jones",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Matthew P.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Lane",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Melissa L.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-09-13",
        "review_rate": 4.9,
        "customer_name": "Trevor Garcia",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Megan C.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-21",
        "review_rate": 4.9,
        "customer_name": "Joseph Campbell",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Tyler R.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "Jessica Taylor",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Amanda G.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.8,
        "customer_name": "John Morales",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kendra B.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-08-21",
        "review_rate": 4.8,
        "customer_name": "Christine Bailey",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Johnathan W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-10",
        "review_rate": 4.8,
        "customer_name": "Andrea Ruiz",
        "service_type": "Garage Door Services",
        "tech_name": "Jeremy P.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Deborah May",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "John C.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "William Moore",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-06-23",
        "review_rate": 5.0,
        "customer_name": "Mr. Justin Turner",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Travis G.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-03-16",
        "review_rate": 4.9,
        "customer_name": "Theresa White",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Paul H.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Ventura and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ventura"
    },
    {
        "date": "2024-04-09",
        "review_rate": 4.9,
        "customer_name": "Christina Joyce",
        "service_type": "Garage Door Installation",
        "tech_name": "Samuel K.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Charles Smith",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kelly E.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-19",
        "review_rate": 4.8,
        "customer_name": "Jennifer Gordon",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nathaniel W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-05",
        "review_rate": 5.0,
        "customer_name": "John Monroe",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Alejandro M.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-29",
        "review_rate": 5.0,
        "customer_name": "Holly Zavala",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jessica M.",
        "review": "I\u2019m very grateful for their outstanding customer support. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-09",
        "review_rate": 4.9,
        "customer_name": "Tina Brown",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kayla B.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-08",
        "review_rate": 4.9,
        "customer_name": "Alyssa Bryant",
        "service_type": "Garage Door Opener",
        "tech_name": "Samantha G.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-19",
        "review_rate": 4.9,
        "customer_name": "Marie Moreno",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bernard L.",
        "review": "They were quick to identify the problem with my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.8,
        "customer_name": "Robert Bates",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jasmin S.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-08",
        "review_rate": 4.9,
        "customer_name": "Carrie Garner",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Daniel U.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Rhonda George",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Peter C.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Nicole Rodriguez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alicia P.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-09",
        "review_rate": 4.9,
        "customer_name": "Curtis Blackburn",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Nathan R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-20",
        "review_rate": 4.9,
        "customer_name": "Bethany Kelly",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Jeremiah P.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-24",
        "review_rate": 4.8,
        "customer_name": "Stephen Lewis",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Melissa M.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-28",
        "review_rate": 4.8,
        "customer_name": "Kelly Smith",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Denise B.",
        "review": "After having issues with my garage door, I decided to call for help, and I\u2019m glad I did. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-23",
        "review_rate": 5.0,
        "customer_name": "Kara Allen",
        "service_type": "Garage Door Opener",
        "tech_name": "Valerie G.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-25",
        "review_rate": 5.0,
        "customer_name": "Timothy Gregory",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Melissa G.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.8,
        "customer_name": "Juan Whitney",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Donna W.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Michael Williams",
        "service_type": "Garage Door Insulation",
        "tech_name": "William S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-02",
        "review_rate": 5.0,
        "customer_name": "Monica English",
        "service_type": "Garage Door Installation",
        "tech_name": "Cheyenne P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-09-29",
        "review_rate": 5.0,
        "customer_name": "Justin Cooper",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Donna G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.8,
        "customer_name": "Sharon Brooks",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Wendy F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-27",
        "review_rate": 5.0,
        "customer_name": "Deborah Pierce",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Matthew J.",
        "review": "I appreciate how they kept me informed throughout the repair process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-18",
        "review_rate": 5.0,
        "customer_name": "Jennifer Morris",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Katie W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-31",
        "review_rate": 4.8,
        "customer_name": "Amanda Lozano",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly A.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-12",
        "review_rate": 4.8,
        "customer_name": "Taylor Day",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Cindy R.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Craig Harrison",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Donna J.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.9,
        "customer_name": "Jamie Gilbert",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Albert A.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Jennifer Gould",
        "service_type": "Garage Door Installation",
        "tech_name": "April B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Deborah Anderson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrea T.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Victoria Bowman",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason W.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Amanda Williams",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "James M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.8,
        "customer_name": "Jared Smith",
        "service_type": "Garage Door Services",
        "tech_name": "Lauren J.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.8,
        "customer_name": "Anna Webster",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joseph M.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-17",
        "review_rate": 4.8,
        "customer_name": "Brandon Macias",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Kirk S.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-06-04",
        "review_rate": 4.9,
        "customer_name": "Elizabeth Harvey",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Joshua S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Darrell Villarreal",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tricia D.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Luis Kennedy",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Kristin B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-07",
        "review_rate": 4.9,
        "customer_name": "Cole Vasquez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andre E.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Pamela Sherman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Rebekah R.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-31",
        "review_rate": 4.8,
        "customer_name": "Francisco Weiss",
        "service_type": "Garage Door Installation",
        "tech_name": "Jessica M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-08-25",
        "review_rate": 4.8,
        "customer_name": "Sarah Smith",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jared K.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Matthew King",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jared C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-02-28",
        "review_rate": 5.0,
        "customer_name": "Melissa Wise",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dylan T.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-03-02",
        "review_rate": 4.9,
        "customer_name": "Leah Frazier",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jesus L.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-07",
        "review_rate": 4.9,
        "customer_name": "Sara Barnett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristen J.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Billy Willis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Billy B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-12-04",
        "review_rate": 4.9,
        "customer_name": "Rachel Estrada",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Patricia R.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.8,
        "customer_name": "Lance Mitchell",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Brandon J.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Irvine and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Irvine"
    },
    {
        "date": "2023-10-04",
        "review_rate": 4.9,
        "customer_name": "Diane Gutierrez",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Richard M.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-24",
        "review_rate": 4.8,
        "customer_name": "Alison Perez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie D.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-27",
        "review_rate": 4.8,
        "customer_name": "Paul Price",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Anna C.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Jermaine Barrett",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Darlene A.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-18",
        "review_rate": 5.0,
        "customer_name": "Melissa Gibson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Sandra B.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "Cheryl Ward",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Bruce H.",
        "review": "The technician was courteous and very knowledgeable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.8,
        "customer_name": "Jeffrey Cobb",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jessica C.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.9,
        "customer_name": "Jason Anthony",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lisa K.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-09",
        "review_rate": 5.0,
        "customer_name": "Tiffany Shelton",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jessica S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Kevin Jones",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Elizabeth W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-13",
        "review_rate": 4.8,
        "customer_name": "Amy Hughes",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Roger G.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Dominique Johnson",
        "service_type": "Garage Door Insulation",
        "tech_name": "Erica T.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-09-28",
        "review_rate": 4.9,
        "customer_name": "Carla Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John W.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-04",
        "review_rate": 5.0,
        "customer_name": "Nancy Johnson",
        "service_type": "Garage Door Services",
        "tech_name": "Cynthia M.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Alexandra Chandler",
        "service_type": "Garage Door Installation",
        "tech_name": "Jamie P.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.8,
        "customer_name": "James Larsen",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Dennis J.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Linda Smith",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Derek H.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.8,
        "customer_name": "Tammy Ramirez",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Maria M.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-08",
        "review_rate": 5.0,
        "customer_name": "Joyce Dillon",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joshua C.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-06-28",
        "review_rate": 4.9,
        "customer_name": "Thomas Webb",
        "service_type": "Garage Door Services",
        "tech_name": "Tina A.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Fred Goodman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Diana S.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "David Nelson",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Larry K.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.9,
        "customer_name": "Jacqueline Trujillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Susan P.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.9,
        "customer_name": "Andrew Craig",
        "service_type": "Garage Door Repair",
        "tech_name": "Phyllis J.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Jose Walters",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ann H.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-30",
        "review_rate": 5.0,
        "customer_name": "Mr. Brett Robinson MD",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Mary R.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-23",
        "review_rate": 4.8,
        "customer_name": "Whitney Benson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Regina H.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Kerry Goodwin",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Lindsey W.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.8,
        "customer_name": "Tracy Perry",
        "service_type": "Garage Door Off Track",
        "tech_name": "Michael P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-03-23",
        "review_rate": 4.8,
        "customer_name": "Joseph Taylor",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Kenneth N.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Mr. Garrett Pacheco",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amber R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Matthew Spears",
        "service_type": "Garage Door Off Track",
        "tech_name": "Thomas P.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Casey Wu",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Anthony B.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-12",
        "review_rate": 4.9,
        "customer_name": "Aaron Stephenson",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Russell B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-14",
        "review_rate": 5.0,
        "customer_name": "Dr. Melissa Cortez",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Seth R.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-07-16",
        "review_rate": 5.0,
        "customer_name": "Xavier Howard",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Tiffany G.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-09-23",
        "review_rate": 4.9,
        "customer_name": "Andrea Williams",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Amber S.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Lisa Case",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Natalie W.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-16",
        "review_rate": 4.8,
        "customer_name": "Jennifer Allen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-24",
        "review_rate": 4.9,
        "customer_name": "Brian Hopkins",
        "service_type": "Garage Door Services",
        "tech_name": "William C.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-03",
        "review_rate": 4.8,
        "customer_name": "Alexis Fleming",
        "service_type": "Garage Door Insulation",
        "tech_name": "Jessica S.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-18",
        "review_rate": 4.9,
        "customer_name": "Eric Jenkins",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Anthony H.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-08-17",
        "review_rate": 5.0,
        "customer_name": "Eric Moody",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Richard P.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.9,
        "customer_name": "Tonya Wood",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Daniel K.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Cody Mcclain",
        "service_type": "Garage Door Services",
        "tech_name": "Todd O.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Dana Cooke",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Robert W.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-21",
        "review_rate": 4.9,
        "customer_name": "Emily Harris",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Glenn G.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.8,
        "customer_name": "Ashley Allen",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Sara F.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-04-09",
        "review_rate": 5.0,
        "customer_name": "Rachael Cook",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Curtis B.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Cory Mcdonald",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Kayla S.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Huntington Beach and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Huntington Beach"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Amanda Hardin",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adam T.",
        "review": "The service was efficient and exceeded my expectations. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-21",
        "review_rate": 5.0,
        "customer_name": "Mary Cobb",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jeremiah G.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.9,
        "customer_name": "Robert White",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shannon J.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-15",
        "review_rate": 4.8,
        "customer_name": "Jessica Tate",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Brandon M.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Amber Wood",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Levi K.",
        "review": "I would rate their service as the best I've ever had for garage work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-15",
        "review_rate": 5.0,
        "customer_name": "Reginald Hart",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melissa C.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-12",
        "review_rate": 4.8,
        "customer_name": "Kathryn Walter",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Rachel J.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-06",
        "review_rate": 4.8,
        "customer_name": "John Simmons",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gerald G.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Samuel Bowers",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Joseph R.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "Sarah Williams",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Aaron S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-04",
        "review_rate": 4.9,
        "customer_name": "Gregory Walker",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Morgan R.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.9,
        "customer_name": "Colleen Allen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Thomas F.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-12",
        "review_rate": 4.9,
        "customer_name": "Abigail Brown",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Charles B.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-06",
        "review_rate": 4.8,
        "customer_name": "Alexander Wright",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Sandra W.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.8,
        "customer_name": "Robin Berry",
        "service_type": "Garage Door Insulation",
        "tech_name": "Brenda H.",
        "review": "After a thorough inspection, my garage door was restored to perfect condition. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-26",
        "review_rate": 4.9,
        "customer_name": "Heather Miller",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Megan C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-09-16",
        "review_rate": 5.0,
        "customer_name": "Kyle Pitts",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-05",
        "review_rate": 4.9,
        "customer_name": "Brittany Reed",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Courtney S.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-14",
        "review_rate": 4.9,
        "customer_name": "Ryan Murillo",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Christina F.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Susan Moody",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Thomas M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Ashley Roman",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Sean S.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-01",
        "review_rate": 4.9,
        "customer_name": "Audrey Anderson",
        "service_type": "Garage Door Opener",
        "tech_name": "Brittney C.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.8,
        "customer_name": "Steven Cross",
        "service_type": "Garage Door Installation",
        "tech_name": "Jose W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-21",
        "review_rate": 5.0,
        "customer_name": "Amber Harris",
        "service_type": "Garage Door Installation",
        "tech_name": "Lisa M.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-06-06",
        "review_rate": 4.9,
        "customer_name": "Nicole Parker",
        "service_type": "Garage Door Installation",
        "tech_name": "Alan W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-09",
        "review_rate": 4.8,
        "customer_name": "Gavin Ruiz",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Ashley M.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-08",
        "review_rate": 4.9,
        "customer_name": "Kristi Yates",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Daniel S.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Phillip Hart",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Jacob P.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Julie Gates",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Christopher P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-16",
        "review_rate": 4.8,
        "customer_name": "Barbara Wilson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Katherine H.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-28",
        "review_rate": 4.8,
        "customer_name": "Gary Strickland",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Julia B.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.8,
        "customer_name": "Sandra Colon",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Jason B.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-03",
        "review_rate": 4.9,
        "customer_name": "Richard Patton",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Pamela L.",
        "review": "I was surprised by how fast the technician completed the work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-11-24",
        "review_rate": 5.0,
        "customer_name": "Samantha Gilmore",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Regina S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-27",
        "review_rate": 4.9,
        "customer_name": "Lawrence Bates DDS",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Elizabeth C.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Michael Smith",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "David L.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.8,
        "customer_name": "Alexander Sanchez",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Donald H.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-29",
        "review_rate": 4.8,
        "customer_name": "Brandon Warren",
        "service_type": "Garage Door Repair",
        "tech_name": "Patricia F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-02",
        "review_rate": 4.9,
        "customer_name": "Steven Hopkins",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Jennifer V.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Kyle Hernandez",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Brian L.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-26",
        "review_rate": 4.9,
        "customer_name": "Kristen Fisher",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Mark M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.9,
        "customer_name": "Lance Woods",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Benjamin S.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-07",
        "review_rate": 5.0,
        "customer_name": "Lori Hawkins",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Barry W.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-10-24",
        "review_rate": 5.0,
        "customer_name": "Angela Kennedy",
        "service_type": "Garage Door Installation",
        "tech_name": "Gary G.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-23",
        "review_rate": 5.0,
        "customer_name": "Mark Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Marissa B.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-25",
        "review_rate": 4.8,
        "customer_name": "Jessica Palmer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Melissa T.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Raymond Ferguson",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Amanda D.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-01-04",
        "review_rate": 4.9,
        "customer_name": "Andrew Miranda",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Cindy L.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "Corey Shepard",
        "service_type": "Garage Door Repair",
        "tech_name": "Ricardo J.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2023-12-25",
        "review_rate": 4.8,
        "customer_name": "Ryan Wallace",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jennifer T.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Ontario and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Ontario"
    },
    {
        "date": "2024-05-23",
        "review_rate": 4.9,
        "customer_name": "Mrs. Crystal Herman DDS",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Julie K.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-13",
        "review_rate": 5.0,
        "customer_name": "Cristian Bentley",
        "service_type": "Garage Door Off Track",
        "tech_name": "Deborah R.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-28",
        "review_rate": 4.9,
        "customer_name": "Jordan Jimenez",
        "service_type": "Garage Door Opener",
        "tech_name": "Tina N.",
        "review": "The technician made sure I understood all my options. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-22",
        "review_rate": 4.9,
        "customer_name": "Nicholas Oconnor",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Mark J.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-02",
        "review_rate": 4.9,
        "customer_name": "Jacob Harrison",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "April A.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-01",
        "review_rate": 4.9,
        "customer_name": "Anthony Fuentes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christine M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-14",
        "review_rate": 4.9,
        "customer_name": "Alexa Payne",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Jaime T.",
        "review": "I had an emergency with my garage door, and they responded quickly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-07",
        "review_rate": 5.0,
        "customer_name": "Elizabeth Guerra",
        "service_type": "Garage Door Installation",
        "tech_name": "Matthew A.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Amber Hahn",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ryan C.",
        "review": "They were respectful of my home and cleaned up after themselves. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-28",
        "review_rate": 4.9,
        "customer_name": "Christina Chen",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Megan G.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-25",
        "review_rate": 4.9,
        "customer_name": "Emily Logan",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Christine C.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-22",
        "review_rate": 4.9,
        "customer_name": "Richard Solis",
        "service_type": "Garage Door Installation",
        "tech_name": "Rebecca W.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-03",
        "review_rate": 4.8,
        "customer_name": "Andrea Castillo",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Michael R.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-15",
        "review_rate": 5.0,
        "customer_name": "Robert Duran DDS",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Shaun H.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Mark Bishop",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Rebecca M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-23",
        "review_rate": 5.0,
        "customer_name": "Darrell Knight",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Elizabeth P.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-03",
        "review_rate": 4.9,
        "customer_name": "Rebecca Hunter",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Eric B.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-07",
        "review_rate": 4.9,
        "customer_name": "Christopher Hall",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Arthur M.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Robbins",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Tamara L.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-14",
        "review_rate": 4.8,
        "customer_name": "Andrew Mahoney",
        "service_type": "Garage Door Installation",
        "tech_name": "Ricardo B.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-17",
        "review_rate": 4.9,
        "customer_name": "Jeffrey Newman",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Makayla H.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-02",
        "review_rate": 4.9,
        "customer_name": "Ashley Graves",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mary J.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-25",
        "review_rate": 4.9,
        "customer_name": "Christopher Montes",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Gordon M.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-24",
        "review_rate": 4.8,
        "customer_name": "Barbara Jenkins",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Calvin L.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-02-19",
        "review_rate": 5.0,
        "customer_name": "Jason Mckinney",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Allen N.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-04",
        "review_rate": 4.9,
        "customer_name": "Olivia Barnes",
        "service_type": "Garage Door Insulation",
        "tech_name": "Lauren S.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-11",
        "review_rate": 4.8,
        "customer_name": "Brandon Garcia",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Anthony S.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Teresa Brewer",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Scott J.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-24",
        "review_rate": 4.9,
        "customer_name": "Pamela Meza",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Alicia B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.8,
        "customer_name": "Danielle Jenkins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Rebecca E.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Jessica Romero",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Brittany R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-10",
        "review_rate": 5.0,
        "customer_name": "Hunter Carney",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Amanda M.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "Rita Peters",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Denise S.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-13",
        "review_rate": 4.8,
        "customer_name": "James Holmes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Morgan S.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-11",
        "review_rate": 5.0,
        "customer_name": "Kevin Smith",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Mary P.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-05",
        "review_rate": 5.0,
        "customer_name": "Jamie Johnson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Kim A.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-07-14",
        "review_rate": 4.9,
        "customer_name": "Richard Moss",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jose J.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-15",
        "review_rate": 5.0,
        "customer_name": "Katelyn Delgado",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Sandra E.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.9,
        "customer_name": "Christopher Mayer",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Carol H.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-04",
        "review_rate": 4.9,
        "customer_name": "Daniel Reed",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Carol G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Claudia Scott",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "William J.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-22",
        "review_rate": 5.0,
        "customer_name": "Larry Martinez",
        "service_type": "Garage Door Installation",
        "tech_name": "Edward G.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Erik Miles",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jason H.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.9,
        "customer_name": "Joel Casey",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Brandon M.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-31",
        "review_rate": 4.8,
        "customer_name": "Bill Miller",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Andrea W.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-04-03",
        "review_rate": 5.0,
        "customer_name": "Heidi Hartman",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ricky B.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-03-01",
        "review_rate": 4.9,
        "customer_name": "Brent Turner",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Michael P.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Christopher Hunt",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Lauren C.",
        "review": "The crew was friendly and very efficient with the garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2024-05-14",
        "review_rate": 4.9,
        "customer_name": "Tammie Yoder",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Shelly S.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-11-13",
        "review_rate": 5.0,
        "customer_name": "Kristi Wood",
        "service_type": "Garage Door Opener",
        "tech_name": "Sara B.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Santa Clarita and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Clarita"
    },
    {
        "date": "2023-10-03",
        "review_rate": 5.0,
        "customer_name": "Crystal Santos",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ryan S.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Jessica Cook",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Molly C.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-20",
        "review_rate": 4.8,
        "customer_name": "Kelly Powers",
        "service_type": "Garage Door Installation",
        "tech_name": "Charlotte D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Jimmy Russell",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "William J.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-30",
        "review_rate": 4.9,
        "customer_name": "Robert Morgan",
        "service_type": "Garage Door Off Track",
        "tech_name": "John W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-15",
        "review_rate": 4.9,
        "customer_name": "Jason Steele",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Mark C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "Andre Thompson",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Eduardo M.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-02",
        "review_rate": 5.0,
        "customer_name": "Victoria Kelly",
        "service_type": "Garage Door Insulation",
        "tech_name": "Bryan C.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.8,
        "customer_name": "Kayla Greene",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Victoria G.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-06",
        "review_rate": 5.0,
        "customer_name": "Adam Murillo",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Christopher T.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-18",
        "review_rate": 5.0,
        "customer_name": "Alexis Santana",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Christopher S.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Aaron Owens",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cody P.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-01",
        "review_rate": 5.0,
        "customer_name": "Stephen Edwards",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-27",
        "review_rate": 4.9,
        "customer_name": "Russell Oliver",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lisa S.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-03",
        "review_rate": 4.9,
        "customer_name": "Robert Brown",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Bryce G.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.9,
        "customer_name": "Katie Reilly",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Michele J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.8,
        "customer_name": "Austin Owen",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Cindy H.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.8,
        "customer_name": "Laura Bates",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Samantha R.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.9,
        "customer_name": "Robert Mills",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Carrie R.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-04",
        "review_rate": 4.9,
        "customer_name": "Melissa Cox",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer R.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-06-07",
        "review_rate": 4.8,
        "customer_name": "Jennifer Perez",
        "service_type": "Garage Door Off Track",
        "tech_name": "Amy J.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Carl Gill",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Benjamin W.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Erica Williams",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Brian M.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-13",
        "review_rate": 4.8,
        "customer_name": "Aaron Perkins Jr.",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Ethan R.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-26",
        "review_rate": 4.8,
        "customer_name": "Heather Rios",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Alexandra S.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Devin Williams",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Dylan D.",
        "review": "Having my garage door repaired was a smooth experience thanks to the team. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-21",
        "review_rate": 4.9,
        "customer_name": "Amanda Cruz",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Katelyn V.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-17",
        "review_rate": 4.8,
        "customer_name": "Amy Harrell",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Amy S.",
        "review": "I couldn't believe how much difference the new garage door opener made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-30",
        "review_rate": 4.9,
        "customer_name": "John Garcia",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Andrea N.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-16",
        "review_rate": 5.0,
        "customer_name": "Michelle Barrett",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Derek O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Brad Mcmahon",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "John C.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Melissa Davis",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Stephanie S.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.8,
        "customer_name": "Janet Gates",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Evelyn T.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Emily Shah",
        "service_type": "Garage Door Installation",
        "tech_name": "Donna C.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-12",
        "review_rate": 4.8,
        "customer_name": "Vincent Moore",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Beth C.",
        "review": "I was relieved to find such reliable and efficient service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-02-11",
        "review_rate": 4.9,
        "customer_name": "Audrey Perez",
        "service_type": "Garage Door Repair",
        "tech_name": "Elizabeth C.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-11",
        "review_rate": 4.8,
        "customer_name": "Daniel Jackson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Daniel R.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Mariah White",
        "service_type": "Garage Door Installation",
        "tech_name": "Adam P.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-11-28",
        "review_rate": 4.8,
        "customer_name": "Christopher Wilson",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Derek K.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-08",
        "review_rate": 5.0,
        "customer_name": "Rachel Mcclure",
        "service_type": "Garage Door Installation",
        "tech_name": "Cody M.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Lawrence Sims",
        "service_type": "Garage Door Opener",
        "tech_name": "Jesse P.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Nancy Young",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Sandra C.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-01-21",
        "review_rate": 5.0,
        "customer_name": "James Phelps",
        "service_type": "Garage Door Repair",
        "tech_name": "Kara P.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-02",
        "review_rate": 5.0,
        "customer_name": "Mary Donovan",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Melody S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-08-24",
        "review_rate": 5.0,
        "customer_name": "Donald Hobbs",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Nathan W.",
        "review": "The technician's work ethic was commendable and appreciated. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-07-20",
        "review_rate": 4.9,
        "customer_name": "Jennifer Lowe",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Joann M.",
        "review": "The experience was smooth and worry-free from beginning to end. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2023-10-23",
        "review_rate": 4.9,
        "customer_name": "Ashley Arroyo",
        "service_type": "Garage Door Services",
        "tech_name": "Kevin J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Robert Alexander",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Christopher E.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Lisa Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "Anna W.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-05-22",
        "review_rate": 4.9,
        "customer_name": "Jessica Combs",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Zachary H.",
        "review": "The service provided was worth every penny. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Simi Valley and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Simi Valley"
    },
    {
        "date": "2024-03-15",
        "review_rate": 5.0,
        "customer_name": "Latoya Orozco",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amber S.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-06",
        "review_rate": 4.9,
        "customer_name": "Jose Davis",
        "service_type": "Garage Door Off Track",
        "tech_name": "Angela C.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-28",
        "review_rate": 4.9,
        "customer_name": "Bethany Taylor",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Jared W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-11",
        "review_rate": 4.9,
        "customer_name": "Jacob Bennett",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Cynthia D.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-14",
        "review_rate": 4.9,
        "customer_name": "Christina Leach",
        "service_type": "Garage Door Insulation",
        "tech_name": "Samantha M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Tonya Jackson",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Erik W.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-09-26",
        "review_rate": 4.8,
        "customer_name": "Heather Simpson",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Beverly F.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-29",
        "review_rate": 5.0,
        "customer_name": "Marc Jones",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Michael M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-29",
        "review_rate": 4.8,
        "customer_name": "Brittany Larsen",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Olivia A.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.8,
        "customer_name": "Keith Anderson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Andrew G.",
        "review": "I can't express how satisfied I am with the garage door installation process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "Francis Torres",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Danielle F.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Kaitlyn Joseph",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Tyler P.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-25",
        "review_rate": 5.0,
        "customer_name": "Richard Vaughn",
        "service_type": "Garage Door Repair",
        "tech_name": "Susan K.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-21",
        "review_rate": 4.9,
        "customer_name": "Jonathan Newton",
        "service_type": "Garage Door Off Track",
        "tech_name": "Kristina R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-17",
        "review_rate": 4.8,
        "customer_name": "David Sweeney",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "John S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-29",
        "review_rate": 4.8,
        "customer_name": "Amy Howard",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Diane R.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.8,
        "customer_name": "Candice Robinson",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Kenneth H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-17",
        "review_rate": 5.0,
        "customer_name": "Connie Boyd",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Matthew M.",
        "review": "The technician was patient and understanding during the process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-07-18",
        "review_rate": 4.9,
        "customer_name": "Charles Howell",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Gina T.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-12",
        "review_rate": 5.0,
        "customer_name": "Cheyenne Snow",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Linda R.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-01",
        "review_rate": 5.0,
        "customer_name": "Shawn Durham",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Robert R.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-06",
        "review_rate": 5.0,
        "customer_name": "Michelle Pittman",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Anthony V.",
        "review": "I\u2019m thankful I chose this company for my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-30",
        "review_rate": 4.8,
        "customer_name": "Tina Kerr",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Ashley W.",
        "review": "My garage door now operates perfectly thanks to their help. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-31",
        "review_rate": 4.9,
        "customer_name": "Ashley Jordan",
        "service_type": "Garage Door Off Track",
        "tech_name": "Sheri W.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-01-02",
        "review_rate": 4.8,
        "customer_name": "Angela Blake",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Richard S.",
        "review": "I couldn\u2019t be more satisfied with my garage door experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-04",
        "review_rate": 4.9,
        "customer_name": "Matthew Stevens",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Ricky H.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-13",
        "review_rate": 5.0,
        "customer_name": "Matthew Myers",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Kyle S.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-05",
        "review_rate": 4.9,
        "customer_name": "Mr. Bryan Wiley",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Michael M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.8,
        "customer_name": "Nathan Stewart",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Ricky C.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-21",
        "review_rate": 5.0,
        "customer_name": "Brian Mccarthy",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Kenneth W.",
        "review": "I felt secure knowing my garage door was being taken care of by professionals. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-20",
        "review_rate": 5.0,
        "customer_name": "Alyssa Phillips",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kristine C.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-09-09",
        "review_rate": 5.0,
        "customer_name": "Christopher Reed",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Thomas N.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-08",
        "review_rate": 4.9,
        "customer_name": "Jonathan Mora",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Traci B.",
        "review": "The technician arrived promptly and got straight to work on my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Morgan Cooper",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Shannon M.",
        "review": "I would recommend them to anyone needing garage door services. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-08",
        "review_rate": 5.0,
        "customer_name": "Marcus Ross",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Katherine C.",
        "review": "The quality of their work is evident in the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-29",
        "review_rate": 5.0,
        "customer_name": "Philip Blake",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Lucas S.",
        "review": "This company has set the standard for garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-19",
        "review_rate": 4.9,
        "customer_name": "Alisha Wilson",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "James S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Nicholas Oliver",
        "service_type": "Garage Door Off Track",
        "tech_name": "Lisa H.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Melissa Ray",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Louis M.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Cody Singleton",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Yvette W.",
        "review": "The team showed up prepared and ready to tackle the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-05",
        "review_rate": 4.8,
        "customer_name": "Stacey Smith",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Danielle D.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.8,
        "customer_name": "Margaret Lopez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Monica F.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Tim Wells",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Rachel F.",
        "review": "My garage door installation was completed ahead of schedule. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Sierra Padilla",
        "service_type": "Garage Door Services",
        "tech_name": "Kristina O.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-02-16",
        "review_rate": 4.9,
        "customer_name": "Joseph James",
        "service_type": "Garage Door Off Track",
        "tech_name": "Andrea S.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-03-09",
        "review_rate": 4.8,
        "customer_name": "John Hawkins",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Phillip H.",
        "review": "From start to finish, the service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Michaela Wiggins",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Joel R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-22",
        "review_rate": 5.0,
        "customer_name": "Caleb Dickson",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jonathan A.",
        "review": "The technician was thorough and left my garage cleaner than before. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2024-05-30",
        "review_rate": 5.0,
        "customer_name": "John Chase",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Alicia C.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.9,
        "customer_name": "Nathan Watkins",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Michael W.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Santa Monica and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Santa Monica"
    },
    {
        "date": "2023-10-26",
        "review_rate": 4.8,
        "customer_name": "Leslie Jimenez",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Andrea D.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-06",
        "review_rate": 5.0,
        "customer_name": "Tonya Sullivan",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Kimberly L.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-14",
        "review_rate": 4.9,
        "customer_name": "Steven Valenzuela",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Dwayne N.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-27",
        "review_rate": 4.8,
        "customer_name": "Pam Herman",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Roger S.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-14",
        "review_rate": 4.9,
        "customer_name": "Shannon Brown",
        "service_type": "Garage Door Off Track",
        "tech_name": "Gary A.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-02",
        "review_rate": 4.8,
        "customer_name": "Maria Cole",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Christine G.",
        "review": "I found their approach to customer service refreshing and effective. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-13",
        "review_rate": 4.9,
        "customer_name": "Christopher Mack",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jean B.",
        "review": "The service exceeded my expectations in every way. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-01",
        "review_rate": 4.8,
        "customer_name": "Julie Walker",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Wendy B.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-21",
        "review_rate": 4.9,
        "customer_name": "Renee Williams",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Bryan S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 4.8,
        "customer_name": "Elizabeth Wheeler",
        "service_type": "Garage Door Opener",
        "tech_name": "Stephen L.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-27",
        "review_rate": 5.0,
        "customer_name": "Adam Green",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Brent R.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-25",
        "review_rate": 4.8,
        "customer_name": "Stanley Love",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Raymond S.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-23",
        "review_rate": 4.9,
        "customer_name": "Kristopher Mckee",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Matthew F.",
        "review": "I was impressed by the speed and quality of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-26",
        "review_rate": 5.0,
        "customer_name": "Amy Torres",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly W.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-22",
        "review_rate": 4.9,
        "customer_name": "Bonnie Robertson",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "Sean R.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-12",
        "review_rate": 4.9,
        "customer_name": "Ashley Marquez",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "David S.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-01",
        "review_rate": 4.9,
        "customer_name": "Jennifer Peters",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Kayla H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-10",
        "review_rate": 4.9,
        "customer_name": "Ashley Thomas",
        "service_type": "Garage Door Services",
        "tech_name": "Donald N.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-26",
        "review_rate": 4.9,
        "customer_name": "Megan Benitez",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "David A.",
        "review": "I am now a loyal customer after this experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-11",
        "review_rate": 4.9,
        "customer_name": "Jesus Lee",
        "service_type": "Garage Door Opener",
        "tech_name": "Victoria M.",
        "review": "I was blown away by their level of expertise and service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-08-13",
        "review_rate": 5.0,
        "customer_name": "Jonathan Bernard",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Kristina O.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-03",
        "review_rate": 5.0,
        "customer_name": "Christine Allison",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Mitchell W.",
        "review": "The level of professionalism displayed was remarkable. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Nicole Decker",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Ashley B.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-11-11",
        "review_rate": 5.0,
        "customer_name": "Krystal Mckay",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Daniel R.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-06",
        "review_rate": 4.9,
        "customer_name": "James Hill",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Eric R.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.9,
        "customer_name": "Christopher Crawford",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Sandra C.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-10",
        "review_rate": 5.0,
        "customer_name": "Andrew Garrett",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Lance Q.",
        "review": "I loved how the team worked seamlessly to get the job done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.8,
        "customer_name": "Karen Watson",
        "service_type": "Garage Door Off Track",
        "tech_name": "Elizabeth J.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-19",
        "review_rate": 4.8,
        "customer_name": "Sheryl Jenkins",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Christian S.",
        "review": "The repairs were done quickly without sacrificing quality. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-30",
        "review_rate": 5.0,
        "customer_name": "Paul Reyes",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Tina B.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Calvin Smith",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Lauren G.",
        "review": "I was grateful for their quick response to my garage door emergency. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-05",
        "review_rate": 5.0,
        "customer_name": "Kevin Parsons",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Courtney B.",
        "review": "My garage door is operating perfectly after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.9,
        "customer_name": "Rebecca Graham",
        "service_type": "Garage Door Insulation",
        "tech_name": "Scott C.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.9,
        "customer_name": "Frank Robertson",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Paul M.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-14",
        "review_rate": 5.0,
        "customer_name": "Linda Watson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Tammy C.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-04",
        "review_rate": 5.0,
        "customer_name": "Tanya Hernandez",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Karla G.",
        "review": "The repair of my garage door was handled exceptionally well. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.9,
        "customer_name": "Adriana Stevenson",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Tracy M.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-19",
        "review_rate": 4.9,
        "customer_name": "Brandy Brown",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Timothy H.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-01-26",
        "review_rate": 5.0,
        "customer_name": "Patrick Jones",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-05-08",
        "review_rate": 5.0,
        "customer_name": "Erica Scott",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jade J.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-24",
        "review_rate": 4.9,
        "customer_name": "Rhonda Jones",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Monica S.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-12-22",
        "review_rate": 4.9,
        "customer_name": "Shelby Marshall",
        "service_type": "Garage Door Insulation",
        "tech_name": "Peter C.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-26",
        "review_rate": 5.0,
        "customer_name": "Paul Robertson",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Shane G.",
        "review": "I was impressed by their prompt arrival and quick turnaround time. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-09-16",
        "review_rate": 4.9,
        "customer_name": "Ronnie Turner",
        "service_type": "Garage Door Insulation",
        "tech_name": "Michelle R.",
        "review": "The attention to detail during the garage door maintenance was impressive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-02-12",
        "review_rate": 5.0,
        "customer_name": "Kristi Norman",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "David S.",
        "review": "The installation of my new garage door was seamless and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-07",
        "review_rate": 5.0,
        "customer_name": "Aaron Morales",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Jennifer Y.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-27",
        "review_rate": 4.8,
        "customer_name": "Leon Smith",
        "service_type": "Garage Door Insulation",
        "tech_name": "Alicia J.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door insulation done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Donald Fox",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Ashley C.",
        "review": "The level of service I received was extraordinary. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2023-10-25",
        "review_rate": 4.9,
        "customer_name": "Melissa Roberson",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Wanda C.",
        "review": "My garage door now functions flawlessly, thanks to their expertise. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-03-28",
        "review_rate": 5.0,
        "customer_name": "Amy Vazquez",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Joyce D.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in Chino and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Chino"
    },
    {
        "date": "2024-04-22",
        "review_rate": 4.9,
        "customer_name": "James Irwin",
        "service_type": "Garage Door Services",
        "tech_name": "Carla H.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-04",
        "review_rate": 4.9,
        "customer_name": "Nicholas Daniels",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Robert M.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-08",
        "review_rate": 4.9,
        "customer_name": "Aimee Parrish",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Christopher Y.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-14",
        "review_rate": 4.9,
        "customer_name": "William Armstrong",
        "service_type": "Garage Door Opener Repair",
        "tech_name": "Ashley A.",
        "review": "Choosing this service for my garage door needs was the best decision. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.9,
        "customer_name": "Erin Welch",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Melissa B.",
        "review": "The technician was prompt and very professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.8,
        "customer_name": "Phillip Huang",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Lori W.",
        "review": "My experience with their garage door service was outstanding. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-08",
        "review_rate": 4.9,
        "customer_name": "Brian Vaughn",
        "service_type": "Garage Door Installation",
        "tech_name": "Lance M.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-26",
        "review_rate": 4.9,
        "customer_name": "Alexander Sharp",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Jennifer S.",
        "review": "I couldn't ask for a better experience with my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-24",
        "review_rate": 4.9,
        "customer_name": "Martin Ruiz",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "James W.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-14",
        "review_rate": 5.0,
        "customer_name": "Kyle Vasquez",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Alex J.",
        "review": "My garage door issue was resolved quickly and professionally. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.9,
        "customer_name": "Tina Mcdaniel",
        "service_type": "Garage Door Repair",
        "tech_name": "Eric H.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-02",
        "review_rate": 5.0,
        "customer_name": "Kim Jackson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Linda D.",
        "review": "I can't believe how much better my garage door operates now. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.8,
        "customer_name": "Christopher Ewing",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Amy W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-10",
        "review_rate": 4.9,
        "customer_name": "Robert Rhodes",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Yolanda K.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-07-12",
        "review_rate": 4.8,
        "customer_name": "James Elliott",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "James W.",
        "review": "I couldn't be happier with the results of their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-14",
        "review_rate": 4.8,
        "customer_name": "Samuel James",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Deanna J.",
        "review": "The technician's expertise was evident throughout the job. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-28",
        "review_rate": 4.8,
        "customer_name": "Andrew Lucas",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda C.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-24",
        "review_rate": 4.8,
        "customer_name": "Alexander Hernandez",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael M.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.8,
        "customer_name": "Mark Summers",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Michael H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-21",
        "review_rate": 4.8,
        "customer_name": "Ian Snyder",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Nicholas C.",
        "review": "My garage door was fixed right the first time without any issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Eric Hood",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Shannon M.",
        "review": "I was pleasantly surprised by how affordable the service was. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-18",
        "review_rate": 5.0,
        "customer_name": "Ryan Turner",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Luis M.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-27",
        "review_rate": 4.9,
        "customer_name": "Monica Pennington",
        "service_type": "Garage Door Repair",
        "tech_name": "Robin M.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-15",
        "review_rate": 4.9,
        "customer_name": "Christopher Hudson",
        "service_type": "Garage Door Services",
        "tech_name": "Gregory T.",
        "review": "Every interaction was pleasant and professional. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-14",
        "review_rate": 5.0,
        "customer_name": "Ann Smith",
        "service_type": "Smart Garage Door Systems",
        "tech_name": "Joshua C.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the smart garage door systems done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-06",
        "review_rate": 5.0,
        "customer_name": "Matthew Barrett",
        "service_type": "Garage Door Off Track",
        "tech_name": "Alexis L.",
        "review": "I\u2019m confident in the repairs made to my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-05",
        "review_rate": 4.8,
        "customer_name": "Jessica Le",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Tommy T.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Jonathan Juarez",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Jocelyn C.",
        "review": "I was highly impressed with their professionalism throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-05",
        "review_rate": 4.9,
        "customer_name": "Danny Harris",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Pamela W.",
        "review": "They truly care about their customers and their needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-03",
        "review_rate": 5.0,
        "customer_name": "Joseph Martin",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Jennifer P.",
        "review": "I appreciated the thorough explanation of the work being done. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Andrew Richards",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Elizabeth J.",
        "review": "I was thrilled with the promptness of their response. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-06",
        "review_rate": 5.0,
        "customer_name": "Richard Kirk",
        "service_type": "Garage Door Off Track",
        "tech_name": "Nathaniel R.",
        "review": "The technicians were polite and very accommodating. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-12",
        "review_rate": 4.8,
        "customer_name": "Aaron Lewis",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Julie A.",
        "review": "Their attention to detail made all the difference in the outcome. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-18",
        "review_rate": 4.9,
        "customer_name": "Nicole Parker",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Robert S.",
        "review": "I appreciated the thoroughness of the inspection before the repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-12-12",
        "review_rate": 4.8,
        "customer_name": "Glen Watts",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Amanda T.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-27",
        "review_rate": 4.9,
        "customer_name": "Thomas Johnson",
        "service_type": "Garage Door Opener",
        "tech_name": "Erica B.",
        "review": "They handled my garage door issues with expertise and care. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-01-04",
        "review_rate": 5.0,
        "customer_name": "David Carroll",
        "service_type": "Garage Door Off Track",
        "tech_name": "Mark W.",
        "review": "I felt at ease knowing my garage door was in good hands. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-09-19",
        "review_rate": 5.0,
        "customer_name": "Ryan Davis",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Alfred P.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-20",
        "review_rate": 4.8,
        "customer_name": "Chad Lowery",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Krystal T.",
        "review": "I was really impressed with the service I received for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-03",
        "review_rate": 4.9,
        "customer_name": "Ashley Key",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Gregory R.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-05-25",
        "review_rate": 4.9,
        "customer_name": "Eileen Butler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Kimberly M.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-27",
        "review_rate": 4.9,
        "customer_name": "Martin Harris",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Willie C.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-15",
        "review_rate": 4.9,
        "customer_name": "Amanda Nielsen",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Stephanie S.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-04-20",
        "review_rate": 4.9,
        "customer_name": "Jeff Hodge",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Angela T.",
        "review": "This company went above and beyond with my garage door service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-02-18",
        "review_rate": 4.8,
        "customer_name": "Rachael Bennett",
        "service_type": "Garage Door Opener Installation",
        "tech_name": "David P.",
        "review": "The cost was reasonable for the quality of service provided. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-03-21",
        "review_rate": 4.9,
        "customer_name": "Megan Foster",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Marcus W.",
        "review": "The technician took great care in performing the job correctly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-06-01",
        "review_rate": 4.8,
        "customer_name": "Billy Reyes",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Kimberly A.",
        "review": "I appreciated the straightforward communication during my service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2024-08-26",
        "review_rate": 4.9,
        "customer_name": "Andrew Murray",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kyle W.",
        "review": "Their expertise was evident in every aspect of the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-25",
        "review_rate": 5.0,
        "customer_name": "George Hines",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Leslie S.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-10-30",
        "review_rate": 4.9,
        "customer_name": "Patrick Wheeler",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Donna S.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in Laguna Niguel and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "Laguna Niguel"
    },
    {
        "date": "2023-11-10",
        "review_rate": 4.8,
        "customer_name": "Catherine Barnett",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Lori D.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-10",
        "review_rate": 4.9,
        "customer_name": "Tiffany Escobar",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Charles H.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-08",
        "review_rate": 4.9,
        "customer_name": "Kayla Calderon",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Shelley W.",
        "review": "The technician made sure everything was to my satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-04",
        "review_rate": 5.0,
        "customer_name": "Bryan Brown",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Megan H.",
        "review": "The technician took the time to answer all of my questions. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-12",
        "review_rate": 4.8,
        "customer_name": "Greg Sawyer",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Wendy R.",
        "review": "I truly appreciated their commitment to customer satisfaction. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-20",
        "review_rate": 4.9,
        "customer_name": "Kelly Hoffman",
        "service_type": "Automatic Garage Door Services",
        "tech_name": "Katherine S.",
        "review": "It was reassuring to see the technician\u2019s expertise in action. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the automatic garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-02",
        "review_rate": 4.9,
        "customer_name": "Taylor Pratt",
        "service_type": "Garage Door Opener",
        "tech_name": "Alicia M.",
        "review": "I felt confident in their abilities after seeing the results. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-22",
        "review_rate": 4.9,
        "customer_name": "Emily Smith",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gina H.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-21",
        "review_rate": 4.8,
        "customer_name": "Thomas Herrera",
        "service_type": "Garage Door Hinge Repair",
        "tech_name": "Crystal S.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door hinge repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-18",
        "review_rate": 4.8,
        "customer_name": "Judy Carpenter",
        "service_type": "Garage Door Spring Replacement",
        "tech_name": "Lisa M.",
        "review": "This was the best service I've had for any home improvement project. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door spring replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-03",
        "review_rate": 4.8,
        "customer_name": "Lauren Lambert",
        "service_type": "Emergency Garage Door Repair",
        "tech_name": "Jason C.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the emergency garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-13",
        "review_rate": 4.9,
        "customer_name": "Shelby Little",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Chad L.",
        "review": "The team worked well together and was very efficient. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-10-02",
        "review_rate": 5.0,
        "customer_name": "Kevin Lamb",
        "service_type": "Garage Door Cable Repair",
        "tech_name": "Jamie M.",
        "review": "I was thoroughly impressed with their garage door safety inspection. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door cable repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Joseph Hughes",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Jennifer C.",
        "review": "I felt informed and confident about the repairs being made. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-23",
        "review_rate": 4.9,
        "customer_name": "Kevin Gordon",
        "service_type": "Garage Door Installation",
        "tech_name": "John L.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Rita Stanley",
        "service_type": "Garage Door Safety Inspections",
        "tech_name": "Joseph M.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door safety inspections done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-26",
        "review_rate": 4.9,
        "customer_name": "Dana Chavez",
        "service_type": "Garage Door Opener",
        "tech_name": "Renee K.",
        "review": "My experience with their garage door service was overwhelmingly positive. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-05-13",
        "review_rate": 5.0,
        "customer_name": "Meghan Mullins",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Patricia E.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-01",
        "review_rate": 4.8,
        "customer_name": "Bob Brown",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Jeremy L.",
        "review": "The technician was knowledgeable and explained everything clearly. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-01",
        "review_rate": 4.9,
        "customer_name": "Edwin Moreno",
        "service_type": "Garage Door Track Repair",
        "tech_name": "Savannah H.",
        "review": "The installation of my custom garage door was flawless. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door track repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-18",
        "review_rate": 4.9,
        "customer_name": "Gregory Espinoza",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Nicole P.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-16",
        "review_rate": 5.0,
        "customer_name": "Cody Stanley",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Barbara C.",
        "review": "I was blown away by how quickly the technician fixed my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-06",
        "review_rate": 5.0,
        "customer_name": "Lisa Woodward",
        "service_type": "Commercial Garage Door Services",
        "tech_name": "Stephanie H.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the commercial garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-29",
        "review_rate": 4.8,
        "customer_name": "Debbie Leach",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Deborah C.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-30",
        "review_rate": 4.9,
        "customer_name": "Charles Lane",
        "service_type": "Residential Garage Door Services",
        "tech_name": "Alicia R.",
        "review": "I appreciated the transparency in pricing for my garage door installation. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the residential garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-29",
        "review_rate": 5.0,
        "customer_name": "Edward Stevens",
        "service_type": "Garage Door Repair",
        "tech_name": "Holly S.",
        "review": "I appreciated the clear communication throughout the service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-11",
        "review_rate": 4.8,
        "customer_name": "Barbara Winters",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Taylor B.",
        "review": "The whole process felt easy and stress-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-03-19",
        "review_rate": 4.9,
        "customer_name": "Francisco Savage",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Cody B.",
        "review": "The follow-up service was just as excellent as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-12-16",
        "review_rate": 5.0,
        "customer_name": "Tyler Black",
        "service_type": "Garage Door Sensor Installation",
        "tech_name": "Kimberly S.",
        "review": "The team was respectful and attentive to my needs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door sensor installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-06",
        "review_rate": 4.9,
        "customer_name": "William Nelson",
        "service_type": "Garage Door Seal Replacement",
        "tech_name": "Joanne D.",
        "review": "The technician provided excellent recommendations for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door seal replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-16",
        "review_rate": 4.8,
        "customer_name": "Richard Flores",
        "service_type": "Custom Garage Door Design",
        "tech_name": "Tanya S.",
        "review": "I can't thank them enough for their wonderful service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the custom garage door design done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-07",
        "review_rate": 4.9,
        "customer_name": "Cassandra Garcia",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Jack K.",
        "review": "I highly value the quick turnaround for my garage door repairs. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-07",
        "review_rate": 4.9,
        "customer_name": "Sarah Warren",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "Timothy S.",
        "review": "After using their garage door service, I know I won\u2019t go anywhere else. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-01-15",
        "review_rate": 4.9,
        "customer_name": "Mary Calderon DDS",
        "service_type": "Garage Door Panel Replacement",
        "tech_name": "Debra C.",
        "review": "I can't recommend this garage door service enough after my experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door panel replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-12",
        "review_rate": 5.0,
        "customer_name": "Samuel Moore",
        "service_type": "Garage Door Broken Spring",
        "tech_name": "David C.",
        "review": "My garage door maintenance was conducted professionally and carefully. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door broken spring done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-23",
        "review_rate": 4.9,
        "customer_name": "Ryan Lee",
        "service_type": "Garage Door Off Track",
        "tech_name": "James H.",
        "review": "I could tell they genuinely cared about their customers. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door off track done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-16",
        "review_rate": 4.9,
        "customer_name": "Brian Farmer",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Yvonne H.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-06",
        "review_rate": 5.0,
        "customer_name": "Kenneth Craig",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Gary F.",
        "review": "I felt like a valued customer throughout the entire process. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-17",
        "review_rate": 4.9,
        "customer_name": "Stephanie Russell",
        "service_type": "Garage Door Noise Reduction",
        "tech_name": "Marissa P.",
        "review": "This service was exactly what I needed for my garage door. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door noise reduction done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-15",
        "review_rate": 4.8,
        "customer_name": "Dennis Crawford",
        "service_type": "Garage Door Maintenance",
        "tech_name": "Shelley W.",
        "review": "My garage door has never functioned better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door maintenance done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-07-05",
        "review_rate": 4.8,
        "customer_name": "Rachel Reyes",
        "service_type": "Garage Door Motor Replacement",
        "tech_name": "Christopher F.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door motor replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-24",
        "review_rate": 4.9,
        "customer_name": "Kathryn Chambers",
        "service_type": "Garage Door Roller Replacement",
        "tech_name": "Adam B.",
        "review": "The process was straightforward and hassle-free. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door roller replacement done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-04-05",
        "review_rate": 4.9,
        "customer_name": "Ryan Garza",
        "service_type": "Garage Door Repair",
        "tech_name": "Sophia W.",
        "review": "Their expertise shone through in their service delivery. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door repair done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-08-22",
        "review_rate": 4.9,
        "customer_name": "Pamela Lawrence",
        "service_type": "Garage Door Remote Programming",
        "tech_name": "Keith G.",
        "review": "I was very pleased with the outcome of my garage door repair. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door remote programming done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-20",
        "review_rate": 4.8,
        "customer_name": "Sara Spencer",
        "service_type": "Garage Door Services",
        "tech_name": "Amanda J.",
        "review": "My garage door is now operating like new, thanks to their work. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door services done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2023-11-29",
        "review_rate": 4.9,
        "customer_name": "Julie Martinez",
        "service_type": "Garage Door Keypad Installation",
        "tech_name": "Christina H.",
        "review": "The follow-up service was just as impressive as the initial visit. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door keypad installation done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-06-12",
        "review_rate": 5.0,
        "customer_name": "Mr. Michael Carter",
        "service_type": "Garage Door Parts and Accessories",
        "tech_name": "Natalie W.",
        "review": "My garage door never worked better after their service. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door parts and accessories done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.8,
        "customer_name": "Christopher Long",
        "service_type": "Garage Door Opener",
        "tech_name": "Michael W.",
        "review": "This team provided exceptional service from start to finish. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door opener done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-09-15",
        "review_rate": 4.9,
        "customer_name": "Jodi Stone",
        "service_type": "Garage Door Balance Adjustment",
        "tech_name": "Janet S.",
        "review": "I was relieved to find such reliable service for my garage door issues. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door balance adjustment done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    },
    {
        "date": "2024-02-22",
        "review_rate": 5.0,
        "customer_name": "Bradley Cochran",
        "service_type": "Garage Door Weatherstripping",
        "tech_name": "Brian Y.",
        "review": "I felt reassured by their knowledge and experience. The technician was professional and thorough. They took the time to explain the process to me and ensured everything was in perfect working order. I'm extremely satisfied with the garage door weatherstripping done in San Clemente and would recommend this service to anyone! Overall, it was a great experience.",
        "location": "San Clemente"
    }
]
export default LocationReviewsData;
